import React, { useContext } from "react"
import { navigate } from "@reach/router"
import moment from "moment"
import { context as userContext } from "context/user"
import { context as localContext } from "context/locale"
import { context as notificationsContext } from "context/notifications"
import { useQuery, useMutation } from "@apollo/client"
import { useSelectedStore, useReplaceParams } from "hooks/index"
import { Layout, Breadcrumbs } from "components/common/dashboard"
import { CampaignForm } from "components/common/marketing/campaigns"
import * as translations from "constants/translations"
import { Link, Text } from "components/service"
import { Button, Spinner, Stack } from "components/kit"
import * as paths from "paths.js"
import * as schemas from "./schemas"

export default ({ campaignId }) => {
  const { selectedStore } = useContext(userContext)
  const { lang } = useContext(localContext)
  const notifications = useContext(notificationsContext)
  const replace = useReplaceParams()
  const storeId = useSelectedStore()

  const { data } = useQuery(schemas.GET_CAMPAIGN, {
    variables: { restaurantId: storeId, id: campaignId },
    fetchPolicy: "network-only",
  })
  const [updateCampaign, { loading }] = useMutation(schemas.UPDATE_CAMPAIGN, {
    variables: {
      restaurantId: storeId,
      id: campaignId,
    },
    onCompleted: () => {
      notifications.show(
        <Text value={translations.CAMPAIGN_UPDATED} />,
        "success"
      )
      navigate(replace(paths.campaigns))
    },
    onError: err => {
      const { body } = err.graphQLErrors[0]?.extensions?.exception || {}

      if (!body.error) {
        for (let key of Object.keys(body)) {
          notifications.show(`${body[key][0]}`, "error")
        }
        return
      }

      return notifications.show(
        <Text value={translations.SOMETHING_WENT_WRONG} />,
        "error"
      )
    },
  })

  return (
    <Layout
      top={
        <div>
          <Breadcrumbs
            links={[paths.marketing, paths.campaigns]}
            path={translations.breadcrumbs.MARKETING_EDIT_CAMPAIGN}
            right={
              <Stack
                className="items-center"
                direction={lang === "en" ? "row" : "row-reverse"}
              >
                <span className="whitespace-nowrap">
                  <span className="font-semibold">
                    {selectedStore.campaignsSmsCredit}
                  </span>
                  <Text
                    value={translations.CREDITS_LEFT}
                    className="inline mx-2 text-gray-700"
                  />
                </span>
                <Link to={paths.campaignSubscriptions}>
                  <Button kind="secondary">
                    <Text value={translations.PURCHASE} />
                  </Button>
                </Link>
              </Stack>
            }
          />
        </div>
      }
    >
      <div>
        <div className="pb-32 flex">
          {!data ? (
            <Spinner />
          ) : (
            <CampaignForm
              initialValues={{
                ...data.campaign,
              }}
              isEdit
              onSubmit={data => {
                updateCampaign({
                  variables: {
                    expiresAt: data.expiresAt || null,
                    ...(data.sentSmsCount === 0 && {
                      startsAt: data.startsAt,
                    }),
                  },
                })
              }}
              bottom={[
                <div className="flex flex-wrap mt-8">
                  <div className="flex-2 mt-2 md:mt-0 w-full md:w-auto">
                    <Link to={paths.campaigns}>
                      <Button kind="secondary" size="base" full>
                        <Text value={translations.CANCEL} />
                      </Button>
                    </Link>
                  </div>
                  <div className="flex-1 flex items-stretch flex-row justify-end mt-2 md:mt-0">
                    <div className="w-full md:w-auto">
                      <Button
                        full
                        kind="primary"
                        size="base"
                        isSpinning={loading}
                      >
                        <Text value={translations.SAVE} />
                      </Button>
                    </div>
                  </div>
                </div>,
              ]}
            />
          )}
        </div>
      </div>
    </Layout>
  )
}
