import React, { useContext, useRef } from 'react';
import { useMutation } from '@apollo/client';
import cx from 'classnames';
import { Formik } from 'formik';

import { Field, Text } from 'components/service';
import * as translations from 'constants/translations';
import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import { Stack, Box, SpinnerAlt } from 'components/kit';
import { Row, Label, Footer } from 'components/form/generic';
import { Input } from 'components/form/elements';
import * as schemas from './schemas';
import * as data from './data';
import { context as localeContext } from 'context/locale';
import { ReactComponent as Links } from 'assets/link.svg';
import { ReactComponent as Success } from 'assets/icons-success.svg';
import GoogleAnalyticsIcon from 'assets/google-analytics.png';
import FacebookIcon from 'assets/facebook.png';
import SnapchatIcon from 'assets/snapchat.png';
import GtmIcon from 'assets/gtm-icon.png';
import { STOREFRONT_TYPES } from 'common/utils/isStoreSfv4';

export default () => {
  const { selectedStore } = useContext(userContext);
  const { lang, direction } = useContext(localeContext);
  return (
    <Formik
      initialValues={{
        gaTrackId: !selectedStore.gaTrackId ? '' : selectedStore.gaTrackId,
        fbadTrackId: !selectedStore.fbadTrackId ? '' : selectedStore.fbadTrackId,
        snapTrackId: !selectedStore.snapTrackId ? '' : selectedStore.snapTrackId,
        gtmTrackId: !selectedStore.gtmTrackId ? '' : selectedStore.gtmTrackId,
      }}
      validationSchema={data.validationSchema}
    >
      {({ values, errors, handleChange, setFieldError, ...rest }) => (
        <Stack direction="col" spacing={2}>
          <Box
            title={<Text className="font-semibold text-lg -mb-2" value={translations.ANALYTICS_TOOLS} />}
            body={
              <div className="pb-2 px-4">
                <Box
                  title={
                    <div className="flex items-center">
                      <img
                        src={GoogleAnalyticsIcon}
                        alt="Google Analytics"
                        className={cx('w-6', direction === 'rtl' ? 'ml-3' : 'mr-3')}
                      />
                      <span>Google Analytics</span>
                    </div>
                  }
                  body={
                    <div className="flex flex-col items-start mx-4 ">
                      <Link url={'https://marketingplatform.google.com/about/analytics/'}>
                        <Text value={translations.LEARN_MORE_ABOUT_GOOGLE} className="inline" />
                        <Links className="inline" />
                      </Link>
                      <Row className="w-full">
                        <Label
                          title={<Text value={translations.GOOGLE_TRACKING_ID} />}
                          subtitle={<Text value={translations.GOOGLE_TRACKING_ID_LIKE} />}
                        >
                          <FieldInput
                            name="gaTrackId"
                            placeholder={
                              lang === 'en' ? translations.GOOGLE_TRACK_ID[0] : translations.GOOGLE_TRACK_ID[1]
                            }
                            handleChange={handleChange}
                            setFieldError={setFieldError}
                            error={errors.gaTrackId}
                            value={values.gaTrackId}
                          />
                        </Label>
                      </Row>
                    </div>
                  }
                />
              </div>
            }
          />
          <Box
            title={<Text className="font-semibold text-lg -mb-2" value={translations.TRACKING_TOOLS} />}
            body={
              <div className="pb-2 px-4">
                <div>
                  <Box
                    title={
                      <div className="flex items-center">
                        <img
                          src={FacebookIcon}
                          alt="Facebook Pixel"
                          className={cx('w-6', direction === 'rtl' ? 'ml-3' : 'mr-3')}
                        />
                        <span>Facebook Pixel</span>
                      </div>
                    }
                    className="w-full"
                    body={
                      <div className="flex flex-col items-start mx-4 mb-6">
                        <Link url={'https://www.facebook.com/business/learn/facebook-ads-pixel'}>
                          <Text value={translations.LEARN_MORE_ABOUT_FACEBOOK} className="inline" />
                          <Links className="inline" />
                        </Link>
                        <Row className="w-full">
                          <Label
                            title={<Text value={translations.FACEBOOK_TRACKING_ID} />}
                            subtitle={<Text value={translations.FACEBOOK_TRACKING_ID_LIKE} />}
                          >
                            <FieldInput
                              name="fbadTrackId"
                              placeholder={lang === 'en' ? translations.FACEBOOK_ID[0] : translations.FACEBOOK_ID[1]}
                              handleChange={handleChange}
                              setFieldError={setFieldError}
                              error={errors.fbadTrackId}
                              value={values.fbadTrackId}
                            />
                          </Label>
                        </Row>
                      </div>
                    }
                  />
                </div>
                <div className="mt-4">
                  <Box
                    title={
                      <div className="flex items-center">
                        <img
                          src={SnapchatIcon}
                          alt="Snapchat Pixel"
                          className={cx('w-6', direction === 'rtl' ? 'ml-3' : 'mr-3')}
                        />
                        <span>Snapchat Pixel</span>
                      </div>
                    }
                    body={
                      <div className="flex flex-col items-start mx-4  mb-6">
                        <Link url={'https://businesshelp.snapchat.com/en-US/article/snap-pixel-about'}>
                          <Text value={translations.LEARN_MORE_ABOUT_SNAPCHAT} className="inline" />
                          <Links className="inline" />
                        </Link>
                        <Row className="w-full">
                          <Label
                            title={<Text value={translations.SNAPCHAT_TRACKING_ID} />}
                            subtitle={<Text value={translations.SNAPCHAT_TRACKING_ID_LIKE} />}
                          >
                            <FieldInput
                              name="snapTrackId"
                              placeholder={lang === 'en' ? translations.SNAPCHAT_ID[0] : translations.SNAPCHAT_ID[1]}
                              handleChange={handleChange}
                              setFieldError={setFieldError}
                              error={errors.snapTrackId}
                              value={values.snapTrackId}
                            />
                          </Label>
                        </Row>
                      </div>
                    }
                  />
                </div>
                {selectedStore.deploymentLocation.toLowerCase() === STOREFRONT_TYPES.EKS && (
                  <div className="mt-4">
                    <Box
                      title={
                        <div class="flex items-center">
                          <div className="flex items-center">
                            <img
                              src={GtmIcon}
                              alt={lang === 'en' ? translations.GTM_TITLE[0] : translations.GTM_TITLE[1]}
                              className={cx('w-6', direction === 'rtl' ? 'ml-3' : 'mr-3')}
                            />
                            <span>{lang === 'en' ? translations.GTM_TITLE[0] : translations.GTM_TITLE[1]}</span>
                          </div>
                          <div className={cx('mx-2 rounded text-xs px-2 py-1 h-5 flex items-center bg-gray-300')}>
                            <Text value={translations.BETA} className={cx('text-primary-nav')} />
                          </div>
                        </div>
                      }
                      body={
                        <div className="flex flex-col items-start mx-4  mb-6">
                          <Link url={'https://support.google.com/tagmanager/answer/6102821?hl=en'}>
                            <Text value={translations.LEARN_MORE_ABOUT_GTM} className="inline" />
                            <Links className="inline" />
                          </Link>
                          <Row className="w-full">
                            <Label
                              title={<Text value={translations.GTM_TRACKING_ID} />}
                              subtitle={
                                <span>
                                  {lang === 'en' ? translations.GTM_HISTORY_DESC[0] : translations.GTM_HISTORY_DESC[1]}
                                  <Link url={'https://support.google.com/tagmanager/answer/7679322?hl=en'}>
                                    {lang === 'en'
                                      ? translations.GTM_HISTORY_LINK[0]
                                      : translations.GTM_HISTORY_LINK[1]}
                                  </Link>
                                </span>
                              }
                            >
                              <FieldInput
                                name="gtmTrackId"
                                placeholder={
                                  lang === 'en' ? translations.GTM_PLACEHOLDER[0] : translations.GTM_PLACEHOLDER[1]
                                }
                                handleChange={handleChange}
                                setFieldError={setFieldError}
                                error={errors.gtmTrackId}
                                value={values.gtmTrackId}
                              />
                            </Label>
                          </Row>
                        </div>
                      }
                    />
                  </div>
                )}
              </div>
            }
          />
        </Stack>
      )}
    </Formik>
  );
};

const FieldInput = ({ name, placeholder, handleChange, setFieldError, error, value }) => {
  const { selectedStore } = useContext(userContext);
  const notifications = useContext(notificationsContext);
  const typingTimeout = useRef();
  const [updateRestaurant, { loading }] = useMutation(schemas.UPDATE_RESTAURANT, {
    variables: { id: selectedStore.id },
    onError: err => {
      let body = err.graphQLErrors[0].extensions.exception.body;
      for (let key of Object.keys(body)) {
        notifications.show(`${key} ${body[key][0]}`, 'error');
      }
    },
    onCompleted: data => {
      notifications.show('Updated successfully');
    },
  });
  const analytics = {
    gaTrackId: {
      regex: /^(UA-\d+-\d+|G-\w+)$/i,
      message: <Text value={translations.ERROR_MSG_GOOGLE_ANALYTICS} />,
    },
    fbadTrackId: {
      regex: /^[\d]{15,16}$/i,
      message: <Text value={translations.ERROR_MSG_FACEBOOK_PIXELS} />,
    },
    snapTrackId: {
      regex: /^[a-z0-9]{8}-+[a-z0-9]{4}-+[a-z0-9]{4}-+[a-z0-9]{4}-+[a-z0-9]{12}$/,
      message: <Text value={translations.ERROR_MSG_SNAPCHAT_PIXELS} />,
    },
    gtmTrackId: {
      regex: /^[A-Z]{3}-+[A-Z0-9]{7}$/,
      message: <Text value={translations.ERROR_MSG_GTM} />,
    },
  };
  return (
    <Field
      type="text"
      autocomplete="off"
      after
      icon={loading ? <SpinnerAlt color={'#3852ca'} borderTopColor={'#d8dae6'} /> : !error && value && <Success />}
      name={name}
      placeholder={placeholder}
      component={Input}
      onChange={e => {
        handleChange(e);
        if (!e.target.value) {
          return updateRestaurant({
            variables: {
              [name]: e.target.value,
            },
          });
        } else if (!analytics[name].regex.test(e.target.value)) {
          return setFieldError(name, analytics[name].message);
        } else {
          clearTimeout(typingTimeout.current);
          return (typingTimeout.current =
            !error &&
            setTimeout(() => {
              updateRestaurant({
                variables: {
                  [name]: e.target.value,
                },
              });
            }, 1000));
        }
      }}
    />
  );
};

const Link = ({ children, url }) => (
  <a className="mb-4 text-primary-base hover:text-primary-700" rel="noopener noreferrer" target="_blank" href={url}>
    {children}
  </a>
);
