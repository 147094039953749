import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import cx from 'classnames';

import { useMobile } from 'hooks';
import { context as userContext } from 'context/user';
import { context as localContext } from 'context/locale';
import { context as customersContext } from 'context/customers';
import { Spinner } from 'components/kit';
import { Field, Text } from 'components/service';
import { Label } from 'components/form/generic';
import { RadioGroup, CheckboxAlt, DatePicker, Stepper } from 'components/form/elements';
import { ReactComponent as AlertCircle } from 'assets/Icons_Warning.svg';
import { SEGMENT_TITLE_MAP, initialSegments } from 'constants/customers';
import { VOUCHER_TYPE, MINIMUM_REQUIREMENTS as VOUCHER_MINIMUM_REQUIREMENTS } from 'pages/marketing/constants';
import {
  TIME_PERIOD,
  START_DATE,
  END_DATE,
  PERCENTAGE,
  AMOUNT,
  FREE_DELIVERY,
  MINIMUM_REQUIREMENTS,
  NONE,
  MINIMUM_ORDER_AMOUNT,
  USAGE_LIMITS,
  USAGE_LIMITS_OPTION_2,
  INFO,
  SMS_CONTENT,
  PLACEHOLDER_SMS_BODY,
  SMS_CONTENT_WARNING,
  VALID_FOR,
  DAYS,
  CAMPAIGN_LANGUAGE,
  ENGLISH,
  ARABIC,
  NO_VOUCHER,
  VOUCHER,
  VALID_FOR_DESCRIPTION,
  CAMPAIGN_GOAL,
  CAMPAIGN_GOAL_DESCRIPTION,
  SMS_CONYENT_WARNING_WITHOUT_LINK,
  NOTE,
} from 'constants/translations';
import { CAMPAIGN_OBJECT_KEYS, CAMPAIGN_LANGUAGES, CAMPAIGN_TYPES } from './constants';
import CampaignTextArea from './CampaignTextArea';
import { CAMPAIGN_GOALS } from './schemas';
import smsCount from './smsCount';
import CampaignDetailsSection from './CampaignDetailsSection';

const CampaignDetailsStep = ({
  setFieldValue,
  setFieldTouched,
  handleChange,
  isEdit = false,
  type,
  voucherType,
  minimumRequirements,
  voucherRedemptionLimitPerUserEnabled,
  sentSmsCount,
  estimateReach,
  estimateReachWithSendingLimit,
  creditsPerMessage,
  campaignLanguage,
  targetedSegment,
  campaignGoal,
  smsBody,
  averageOrderValue,
  totalOrders,
}) => {
  const [smsError, setSmsError] = useState(false);
  const isMobile = useMobile();
  const today = new Date();
  const tomorrow = today.setDate(today.getDate() + 1);
  const {
    selectedStore: { currency, id: selectedStoreId },
  } = useContext(userContext);
  const { lang, translate, direction } = useContext(localContext);
  const { campaignGoals, setCampaignGoals, storefrontDefaultLanguage, setStorefrontDefaultLanguage } = useContext(
    customersContext,
  );
  const targetedSegmentTitle =
    targetedSegment === '-' || !targetedSegment
      ? undefined
      : [initialSegments[targetedSegment].titleEn, initialSegments[targetedSegment].titleAr];

  const handleLanguageDependantCampaignGoalAutoFill = (campaignGoalTemp, campaignLanguageTemp) => {
    const campaignBody =
      campaignLanguageTemp === CAMPAIGN_LANGUAGES.ENGLISH ? campaignGoalTemp.bodyEn : campaignGoalTemp.bodyAr;
    setFieldValue(CAMPAIGN_OBJECT_KEYS.SMS_BODY, campaignBody);
    const smsCountResult = smsCount(campaignBody);
    setFieldValue(CAMPAIGN_OBJECT_KEYS.CREDITS_PER_MESSAGE, smsCountResult.messages);
    setFieldValue(CAMPAIGN_OBJECT_KEYS.ESTIMATED_CREDITS, estimateReach * smsCountResult.messages);
  };

  const handleCampaignGoalAutoFill = (campaignGoalTemp, campaignLanguageTemp) => {
    setFieldValue(CAMPAIGN_OBJECT_KEYS.VOUCHER_TYPE, campaignGoalTemp.voucherType);
    setFieldValue(CAMPAIGN_OBJECT_KEYS.VOUCHER_AMOUNT, campaignGoalTemp.voucherAmount);
    setFieldValue(CAMPAIGN_OBJECT_KEYS.VALID_FOR, campaignGoalTemp.voucherValidUntil);
    setFieldValue(CAMPAIGN_OBJECT_KEYS.VOUCHER_MIN_SUBTOTAL_AMOUNT, campaignGoalTemp.voucherMinSubtotalAmount);
    setFieldValue(
      CAMPAIGN_OBJECT_KEYS.VOUCHER_REDEMPTION_LIMIT_PER_USER,
      campaignGoalTemp.voucherRedemptionLimitPerUser,
    );
    setFieldValue(
      CAMPAIGN_OBJECT_KEYS.VOUCHER_REDEMPTION_LIMIT_PER_USER,
      campaignGoalTemp.voucherRedemptionLimitPerUser,
    );
    handleLanguageDependantCampaignGoalAutoFill(campaignGoalTemp, campaignLanguageTemp);
  };

  const matchCampaignGoalToTargetedSegment = (campaignGoalsArray, targetedSegmentValue, campaignLanguageTemp) => {
    const targetedSegmentCampaignGoal = campaignGoalsArray.find(campaignGoalObj =>
      campaignGoalObj.recommendedCrmSegmentId.includes(targetedSegmentValue),
    );
    if (targetedSegmentCampaignGoal) {
      setFieldValue(CAMPAIGN_OBJECT_KEYS.CAMPAIGN_GOAL, targetedSegmentCampaignGoal.titleEn);
      handleCampaignGoalAutoFill(targetedSegmentCampaignGoal, campaignLanguageTemp);
    }
  };

  useQuery(CAMPAIGN_GOALS, {
    variables: {
      storeId: selectedStoreId,
    },
    skip: type === CAMPAIGN_TYPES.RETENTION || campaignGoals.length > 0,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      setCampaignGoals(data?.campaignGoals?.campaignGoals || []);
      setStorefrontDefaultLanguage(data?.campaignGoals?.storefrontDefaultLanguage);
      setFieldValue(CAMPAIGN_OBJECT_KEYS.CAMPAIGN_LANGUAGE, data?.campaignGoals?.storefrontDefaultLanguage);
      matchCampaignGoalToTargetedSegment(
        data?.campaignGoals?.campaignGoals,
        targetedSegment,
        data?.campaignGoals?.storefrontDefaultLanguage,
      );
    },
  });

  useEffect(() => {
    if (type === CAMPAIGN_TYPES.SEGMENT_TARGETING) {
      if (storefrontDefaultLanguage && !campaignGoal && storefrontDefaultLanguage !== campaignLanguage)
        setFieldValue(CAMPAIGN_OBJECT_KEYS.CAMPAIGN_LANGUAGE, storefrontDefaultLanguage);
      if (targetedSegment && campaignGoals.length > 0 && !campaignGoal)
        matchCampaignGoalToTargetedSegment(campaignGoals, targetedSegment, storefrontDefaultLanguage);
    }
  }, []);

  return (
    <div className="flex flex-col-reverse md:flex-row pt-10 w-full pb-40 gap-6">
      <div className="md:w-8/12 w-full">
        {type === CAMPAIGN_TYPES.SEGMENT_TARGETING && (
          <div className="mb-12 w-4/5 lg:w-full">
            <Label
              title={
                <div>
                  <Text className="font-semibold text-lg pb-2" value={CAMPAIGN_GOAL} />
                  <Text className="text-sm text-gray-500 font-normal pb-2" value={CAMPAIGN_GOAL_DESCRIPTION} />
                </div>
              }
            >
              {campaignGoals?.length > 0 ? (
                <Field
                  name={CAMPAIGN_OBJECT_KEYS.CAMPAIGN_GOAL}
                  items={campaignGoals.map(campaignGoalObj => ({
                    title: <Text value={[campaignGoalObj.titleEn, campaignGoalObj.titleAr]} />,
                    value: campaignGoalObj.titleEn,
                  }))}
                  size={isMobile ? 'mobile' : 'base'}
                  component={RadioGroup}
                  onChange={e => {
                    handleChange(e);
                    const campaignGoalTemp = campaignGoals.find(
                      campaignGoalObj => campaignGoalObj.titleEn === e.target.value,
                    );
                    handleCampaignGoalAutoFill(campaignGoalTemp, campaignLanguage);
                  }}
                />
              ) : (
                <Spinner />
              )}
            </Label>
          </div>
        )}
        {!isEdit && (
          <div className="mb-12">
            <div className="mb-6 w-3/5 lg:w-full">
              <Label title={<Text className="font-semibold text-lg" value={VOUCHER} />}>
                <Field
                  name={CAMPAIGN_OBJECT_KEYS.VOUCHER_TYPE}
                  size={isMobile ? 'mobile' : 'base'}
                  items={[
                    {
                      title: <Text value={NO_VOUCHER} />,
                      value: VOUCHER_TYPE.NO_VOUCHER,
                    },
                    {
                      title: <Text value={PERCENTAGE} />,
                      value: VOUCHER_TYPE.PERCENTAGE,
                    },
                    {
                      title: <Text value={AMOUNT} />,
                      value: VOUCHER_TYPE.AMOUNT,
                    },
                    {
                      title: <Text value={FREE_DELIVERY} />,
                      value: VOUCHER_TYPE.FREE_DELIVERY,
                    },
                  ]}
                  component={RadioGroup}
                  onChange={handleChange}
                />
              </Label>
            </div>
            {(voucherType === VOUCHER_TYPE.PERCENTAGE || voucherType === VOUCHER_TYPE.AMOUNT) && (
              <div className="mb-4">
                <Label title={<Text value={AMOUNT} />}>
                  <Field
                    name={CAMPAIGN_OBJECT_KEYS.VOUCHER_AMOUNT}
                    left={voucherType === VOUCHER_TYPE.PERCENTAGE && '%'}
                    // Switched from && to ternary to fix this warning:
                    // Warning: Received `false` for a non-boolean attribute `max`.
                    // If you want to write it to the DOM, pass a string instead: max="false" or max={value.toString()}.
                    // If you used to conditionally omit it with max={condition && value},
                    // pass max={condition ? value : undefined} instead.
                    max={voucherType === VOUCHER_TYPE.PERCENTAGE ? 100 : undefined}
                    min={1}
                    step={1}
                    placeholder={0}
                    component={Stepper}
                  />
                </Label>
              </div>
            )}
            {voucherType !== VOUCHER_TYPE.NO_VOUCHER && (
              <div>
                <div className="mb-6">
                  <Label title={<Text value={VALID_FOR} />}>
                    <Field
                      name={CAMPAIGN_OBJECT_KEYS.VALID_FOR}
                      left={translate(DAYS)}
                      min={1}
                      step={1}
                      placeholder={0}
                      component={Stepper}
                    />
                  </Label>
                  <Text className="text-xs text-gray-600 mt-2" value={VALID_FOR_DESCRIPTION} />
                </div>
                <div className="mb-6 w-4/5 md:w-3/5">
                  <Label title={<Text value={MINIMUM_REQUIREMENTS} />}>
                    <Field
                      name={CAMPAIGN_OBJECT_KEYS.MINIMUM_REQUIREMENTS}
                      size={isMobile ? 'mobile' : 'base'}
                      items={[
                        {
                          title: <Text value={NONE} />,
                          value: VOUCHER_MINIMUM_REQUIREMENTS.NONE,
                        },
                        {
                          title: <Text value={MINIMUM_ORDER_AMOUNT} />,
                          value: VOUCHER_MINIMUM_REQUIREMENTS.MINIMUM_ORDER_AMOUNT,
                        },
                      ]}
                      component={RadioGroup}
                    />
                  </Label>
                </div>
                {minimumRequirements === VOUCHER_MINIMUM_REQUIREMENTS.MINIMUM_ORDER_AMOUNT && (
                  <div className="mb-6">
                    <Field
                      name={CAMPAIGN_OBJECT_KEYS.VOUCHER_MIN_SUBTOTAL_AMOUNT}
                      left={translate(currency)}
                      min={0}
                      step={1}
                      placeholder={0}
                      component={Stepper}
                      validation={n => n >= 0}
                    />
                  </div>
                )}
                <Label title={<Text value={USAGE_LIMITS} />}>
                  <div className="mt-2">
                    <Field
                      name={CAMPAIGN_OBJECT_KEYS.VOUCHER_REDEMPTION_LIMIT_PER_USER_ENABLED}
                      title={<Text value={USAGE_LIMITS_OPTION_2} />}
                      component={CheckboxAlt}
                      onChange={handleChange}
                    />
                    {voucherRedemptionLimitPerUserEnabled && (
                      <div className="mt-3">
                        <Field
                          name={CAMPAIGN_OBJECT_KEYS.VOUCHER_REDEMPTION_LIMIT_PER_USER}
                          min={0}
                          step={1}
                          placeholder={0}
                          component={Stepper}
                          onChange={handleChange}
                        />
                      </div>
                    )}
                  </div>
                </Label>
              </div>
            )}
          </div>
        )}
        <hr className="mb-12" />
        <div>
          <Text className="font-semibold text-lg pb-6" value={INFO} />
          <div className="mb-6 md:w-3/5">
            <Label title={<Text value={CAMPAIGN_LANGUAGE} />}>
              <Field
                name={CAMPAIGN_OBJECT_KEYS.CAMPAIGN_LANGUAGE}
                size={isMobile ? 'mobile' : 'base'}
                items={[
                  {
                    title: ENGLISH[0],
                    value: CAMPAIGN_LANGUAGES.ENGLISH,
                  },
                  {
                    title: ARABIC[1],
                    value: CAMPAIGN_LANGUAGES.ARABIC,
                  },
                ]}
                component={RadioGroup}
                onChange={e => {
                  handleChange(e);
                  if (type === CAMPAIGN_TYPES.SEGMENT_TARGETING) {
                    const campaignGoalTemp = campaignGoals.find(
                      campaignGoalObj => campaignGoalObj.titleEn === campaignGoal,
                    );
                    handleLanguageDependantCampaignGoalAutoFill(campaignGoalTemp, e.target.value);
                  }
                }}
              />
            </Label>
          </div>
          <div className="mb-8">
            <Label title={<Text value={SMS_CONTENT} />}>
              <Field
                rows="6"
                name={CAMPAIGN_OBJECT_KEYS.SMS_BODY}
                disabled={isEdit}
                placeholder={
                  campaignLanguage === CAMPAIGN_LANGUAGES.ENGLISH ? PLACEHOLDER_SMS_BODY[0] : PLACEHOLDER_SMS_BODY[1]
                }
                component={CampaignTextArea}
                onChange={e => {
                  handleChange(e);
                  setFieldTouched(CAMPAIGN_OBJECT_KEYS.SMS_BODY, true);
                }}
                onBlur={() => {
                  setFieldValue(
                    CAMPAIGN_OBJECT_KEYS.ESTIMATED_CREDITS,
                    type === CAMPAIGN_TYPES.RETENTION
                      ? estimateReachWithSendingLimit * creditsPerMessage
                      : estimateReach * creditsPerMessage,
                  );
                }}
                max={smsCount(smsBody).perMessage * 3}
                translate={translate}
                setFieldValue={setFieldValue}
                voucherType={voucherType}
                lang={campaignLanguage === CAMPAIGN_LANGUAGES.ENGLISH ? 'en' : 'ar'}
                direction={campaignLanguage === CAMPAIGN_LANGUAGES.ENGLISH ? 'ltr' : 'rtl'}
                setSmsError={setSmsError}
              />
            </Label>
            <Text className="text-xs text-gray-600 mt-2" value={SMS_CONTENT_WARNING} />
          </div>
          <div className="h-32">
            {smsError && (
              <div className="w-full bg-zyda-yellow-20 flex flex-col p-4" dir={direction}>
                <span className="flex flex-grow">
                  <AlertCircle className="text-zyda-orange-200 fill-current inline mr-2 transform scale-75" />
                  <Text className="pt-1 text-zyda-orange-200" value={NOTE} />
                </span>
                <Text className="text-sm text-gray-600 font-normal px-8" value={SMS_CONYENT_WARNING_WITHOUT_LINK} />
              </div>
            )}
          </div>
        </div>
        {type === CAMPAIGN_TYPES.RETENTION && (
          <div>
            <Text className="font-semibold text-lg pb-2" value={TIME_PERIOD} />
            <div className={cx('flex flex-row justify-between w-8/12', lang === 'en' ? 'pr-4' : 'pl-4')}>
              <div className="self-start">
                <Label title={<Text value={START_DATE} />}>
                  <Field
                    name={CAMPAIGN_OBJECT_KEYS.STARTS_AT}
                    disabled={isEdit && sentSmsCount !== 0}
                    minDate={today}
                    component={DatePicker}
                    onChange={e => {
                      handleChange(e);
                      setFieldTouched(CAMPAIGN_OBJECT_KEYS.EXPIRES_AT, true);
                    }}
                    dateFormat={lang === 'en' ? 'MMM d, yyyy' : 'MMMM d, yyyy'}
                  />
                </Label>
              </div>
              <div className="self-start">
                <Label title={<Text value={END_DATE} />}>
                  <Field
                    name={CAMPAIGN_OBJECT_KEYS.EXPIRES_AT}
                    component={DatePicker}
                    onChange={e => {
                      handleChange(e);
                      setFieldTouched(CAMPAIGN_OBJECT_KEYS.EXPIRES_AT, true);
                    }}
                    minDate={tomorrow}
                    isRemovable
                    dateFormat={lang === 'en' ? 'MMM d, yyyy' : 'MMMM d, yyyy'}
                  />
                </Label>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="justify-end whitespace-nowrap w-full sticky top-0 bg-white pt-4 md:pt-0 md:w-4/12 md:relative">
        <CampaignDetailsSection
          targetedSegment={type === CAMPAIGN_TYPES.SEGMENT_TARGETING && targetedSegmentTitle}
          estimateReach={type === CAMPAIGN_TYPES.RETENTION ? estimateReachWithSendingLimit : estimateReach}
          lang={lang}
          averageOrderValue={averageOrderValue}
          totalOrders={totalOrders}
        />
      </div>
    </div>
  );
};
CampaignDetailsStep.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  type: PropTypes.oneOf([CAMPAIGN_TYPES.RETENTION, CAMPAIGN_TYPES.SEGMENT_TARGETING]),
  voucherType: PropTypes.oneOf([
    VOUCHER_TYPE.FREE_DELIVERY,
    VOUCHER_TYPE.PERCENTAGE,
    VOUCHER_TYPE.AMOUNT,
    VOUCHER_TYPE.NO_VOUCHER,
  ]),
  minimumRequirements: PropTypes.oneOf([
    VOUCHER_MINIMUM_REQUIREMENTS.NONE,
    VOUCHER_MINIMUM_REQUIREMENTS.MINIMUM_ORDER_AMOUNT,
  ]),
  voucherRedemptionLimitPerUserEnabled: PropTypes.bool,
  sentSmsCount: PropTypes.number,
  estimateReach: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  estimateReachWithSendingLimit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  creditsPerMessage: PropTypes.number,
  campaignLanguage: PropTypes.oneOf([CAMPAIGN_LANGUAGES.ENGLISH, CAMPAIGN_LANGUAGES.ARABIC]).isRequired,
  targetedSegment: PropTypes.oneOf([
    SEGMENT_TITLE_MAP.ALL_CUSTOMERS,
    SEGMENT_TITLE_MAP.CHAMPIONS,
    SEGMENT_TITLE_MAP.LOYAL,
    SEGMENT_TITLE_MAP.PROMISING,
    SEGMENT_TITLE_MAP.NEW,
    SEGMENT_TITLE_MAP.LOW_VALUE,
    SEGMENT_TITLE_MAP.NEEDS_ATTENTION,
  ]),
  campaignGoal: PropTypes.string,
  smsBody: PropTypes.string,
  averageOrderValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  totalOrders: PropTypes.number,
};

export default CampaignDetailsStep;
