import React, { useContext } from 'react';
import moment from 'moment';
import { useQuery, NetworkStatus } from '@apollo/client';
import cx from 'classnames';
import ReactTooltip from 'react-tooltip';

import { useQueryState, useSelectedStore } from 'hooks';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import { Text } from 'components/service';
import DATE_RANGES from 'constants/date';
import { SALES_ANALYTICS_LINKS } from 'constants/helperLinks';
import { breadcrumbs, ANALYTICS_TOOLTIP_INFORMATION } from 'constants/translations';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { Spinner } from 'components/kit';
import { DateRange } from 'components/common/analytics';
import Fallback from 'components/common/Fallback';
import { parseRange, stringifyRange, getRange, toRangeVariable } from 'utils/date';
import { ReactComponent as AlertCircle } from 'assets/Icons_Warning.svg';
import Sales from '../../Main/Sales';
import * as schemas from '../../Main/schemas';
import { ANALYTICS_TAB_NAMES } from '../../constants';
import momentLanguage from '../../utils';

const SalesAnalytics = () => {
  const notifications = useContext(notificationsContext);
  const storeId = useSelectedStore();
  const { selectedStore } = useContext(userContext);
  const { direction, lang } = useContext(localeContext);
  const [query, setQuery] = useQueryState({
    type_date: DATE_RANGES.THIRTY_DAYS,
    date: 'day',
    range: stringifyRange(getRange(DATE_RANGES.THIRTY_DAYS)),
    main_selection: ANALYTICS_TAB_NAMES.totalSales.snakeCaseName,
    behavior_selection: ANALYTICS_TAB_NAMES.totalSales.snakeCaseName,
    behavior_date: 'day',
  });

  const range = parseRange(query.range);
  const rangeVariable = toRangeVariable(range);

  const length = breadcrumbs.SALES_ANALYTICS[0].length - 1;

  const { data, error, refetch, networkStatus } = useQuery(schemas.MAIN_VIEW, {
    variables: {
      storeId,
      range: rangeVariable,
    },
    notifyOnNetworkStatusChange: true,
  });

  moment.locale(momentLanguage(lang));

  return (
    <Layout
      top={
        <Breadcrumbs
          helperLinks={SALES_ANALYTICS_LINKS}
          customTitle={
            <div className={cx('flex', lang === 'en' ? 'flex-row' : 'flex-row-reverse')}>
              <Text
                className="text-2xl font-bold text-black"
                value={[breadcrumbs.SALES_ANALYTICS[0][length], breadcrumbs.SALES_ANALYTICS[1][length]]}
              />
              <div>
                <AlertCircle
                  data-tip
                  data-for="salesAnalyticsTooltip"
                  className="text-gray-600 fill-current inline mr-2 ml-1 mt-1"
                  style={{ transform: 'scale(0.75)' }}
                />
                <ReactTooltip place="bottom" type="dark" effect="solid" id="salesAnalyticsTooltip">
                  <ul className="list-disc" dir={lang === 'en' ? 'ltr' : 'rtl'}>
                    {ANALYTICS_TOOLTIP_INFORMATION.map(singleInformationLine => (
                      <li>
                        <Text value={singleInformationLine} />
                      </li>
                    ))}
                  </ul>
                </ReactTooltip>
              </div>
            </div>
          }
          right={
            <DateRange
              queryTypeDate={query.type_date}
              range={range}
              selectedStoreCreatedAt={selectedStore.createdAt}
              notifications={notifications}
              setQuery={setQuery}
              direction={direction}
            />
          }
        />
      }
    >
      <div className="pb-20" style={{ direction }}>
        <div className="w-full mb-12">
          {error ? (
            <Fallback refetch={refetch} />
          ) : !data || networkStatus === NetworkStatus.refetch ? (
            <Spinner />
          ) : (
            <Sales
              type={query.type_date}
              date={query.date}
              header={data.analyticsMainView}
              selected={query.main_selection}
              range={rangeVariable}
              onPrecionChange={date => setQuery({ date })}
              onSelect={value => setQuery({ main_selection: value })}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default SalesAnalytics;
