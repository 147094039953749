import {
  FREE_PLAN_ACCESS_MAP,
  STANDARD_PLAN_ACCESS_MAP,
  GROWTH_PLAN_ACCESS_MAP,
  ACCESS_MAP_KEYS,
  PLAN_TYPES,
  STORE_ID_PATH_STRING,
} from 'constants/featureAccessMatrix';
/**
 * Feature Access Interface
 */
export interface IFeatureAccess {
  getFeaturePath: (pathname: string, storeId: string) => string;
  getAccessMapPlanData: (plan: string) => Record<string, any>;
  createAccessMap: (plan: string) => Map<string, Map<string, any>>;
}

/**
 * Feature Access Module
 */
const FeatureAccess: IFeatureAccess = {
  getFeaturePath: (pathname, storeId) => pathname.replace(storeId, STORE_ID_PATH_STRING),
  getAccessMapPlanData: plan => {
    switch (plan) {
      case PLAN_TYPES.FREE:
        return FREE_PLAN_ACCESS_MAP;
      case PLAN_TYPES.STANDARD:
        return STANDARD_PLAN_ACCESS_MAP;
      case PLAN_TYPES.GROWTH:
        return GROWTH_PLAN_ACCESS_MAP;
      default:
        return FREE_PLAN_ACCESS_MAP;
    }
  },
  createAccessMap: plan => {
    const subscribedPlan = FeatureAccess.getAccessMapPlanData(plan);
    return new Map([
      [ACCESS_MAP_KEYS.SIDE, new Map<string, any>(subscribedPlan[ACCESS_MAP_KEYS.SIDE])],
      [ACCESS_MAP_KEYS.LAYOUT, new Map<string, any>(subscribedPlan[ACCESS_MAP_KEYS.LAYOUT])],
      [ACCESS_MAP_KEYS.FEATURES, new Map<string, any>(subscribedPlan[ACCESS_MAP_KEYS.FEATURES])],
    ]);
  },
};

export default FeatureAccess;
