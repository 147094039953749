import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ArabicNumbers } from 'react-native-arabic-numbers';
import ReactTooltip from 'react-tooltip';

import { context as userContext } from 'context/user';
import { Text } from 'components/service';
import { SpinnerAlt } from 'components/kit';
import {
  ESTIMATED_RESULTS,
  CAMPIAGNS_DETAILS_SECTION_ESTIMATE_REACH_CUSTOMERS,
  TARGETED_SEGMENT,
  AVERAGE_ORDER_VALUE,
  AVERAGE_ORDER_VALUE_ROI,
  INSUFFICIENT_AVERAGE_ORDER_VALUE_ROI,
  INSUFFICIENT_TOTAL_ORDERS_ROI,
} from 'constants/translations';
import { ReactComponent as AlertCircle } from 'assets/Icons_Warning.svg';

const CampaignDetailsSection = ({
  targetedSegment,
  loading = false,
  estimateReach,
  lang,
  averageOrderValue,
  totalOrders,
}) => {
  const { selectedStore } = useContext(userContext);
  const { currency } = selectedStore;

  const averageOrderValueDisplay =
    lang === 'en' && averageOrderValue > 0 ? averageOrderValue : ArabicNumbers(averageOrderValue);
  const profit =
    averageOrderValue !== 0 && estimateReach !== '--' && averageOrderValue !== '--'
      ? Number.parseFloat(averageOrderValue * 0.1 * estimateReach).toFixed(2)
      : 0;
  const profitDisplay = lang === 'en' ? profit : ArabicNumbers(profit);
  return (
    <div
      className={cx(
        'flex flex-row md:flex-col justify-between md:px-8 border-gray-400 border-b pb-2 md:sticky md:top-8',
        lang === 'en' ? 'md:border-l md:border-b-0 md:mr-4' : 'md:border-r md:border-b-0 md:ml-4',
      )}
    >
      {targetedSegment && (
        <div className="mb-6">
          <Text value={TARGETED_SEGMENT} className="mb-1 text-gray-600" />
          <Text value={targetedSegment} className="font-semibold text-lg pt-1" />
        </div>
      )}
      <div className="mb-6">
        <Text value={ESTIMATED_RESULTS} className="mb-1 text-gray-600" />
        {loading ? (
          <SpinnerAlt color="primary-base" />
        ) : (
          <div className="flex flex-row">
            <p className={cx('font-semibold text-lg', lang === 'en' ? 'pr-2' : 'pl-2')}>
              {estimateReach === 0
                ? '-'
                : lang === 'en' && estimateReach !== 0
                ? estimateReach
                : ArabicNumbers(estimateReach)}
            </p>
            <Text value={CAMPIAGNS_DETAILS_SECTION_ESTIMATE_REACH_CUSTOMERS} className="pt-1" />
          </div>
        )}
      </div>
      <div>
        <Text value={AVERAGE_ORDER_VALUE} className="mb-1 text-gray-600" />
        <div className="flex flex-row">
          <p className={cx('font-semibold text-lg', lang === 'en' ? 'pr-2' : 'pl-2')}>
            {averageOrderValue === 0 || averageOrderValue === -1 ? '--' : averageOrderValueDisplay}
          </p>
          <div>
            <Text value={currency} className="pt-1" />
          </div>
          <div>
            <AlertCircle
              data-tip
              data-for="salesAnalyticsTooltip"
              className="text-gray-600 fill-current inline mr-2 ml-1"
              style={{ transform: 'scale(0.75)' }}
            />
            <ReactTooltip place="bottom" type="dark" effect="solid" id="salesAnalyticsTooltip">
              <Text
                value={
                  averageOrderValue > 0
                    ? totalOrders >= 10
                      ? AVERAGE_ORDER_VALUE_ROI(
                          averageOrderValueDisplay,
                          profitDisplay,
                          lang === 'en' ? currency.titleEn : currency.titleAr,
                        )
                      : INSUFFICIENT_TOTAL_ORDERS_ROI
                    : INSUFFICIENT_AVERAGE_ORDER_VALUE_ROI
                }
              />
            </ReactTooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

CampaignDetailsSection.propTypes = {
  targetedSegment: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  estimateReach: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  lang: PropTypes.oneOf(['en', 'ar']).isRequired,
  averageOrderValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  totalOrders: PropTypes.number,
};

export default CampaignDetailsSection;
