import { gql } from "@apollo/client"

export const UNINSTALL_FB = gql`
  mutation uninstallFB(
    $restaurantId: String!
  ) {
    uninstallFB(
      restaurantId: $restaurantId
    ) {
      connection
    }
  }
`
