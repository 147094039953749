import React from 'react';
import cx from 'classnames';
import { ArabicNumbers } from 'react-native-arabic-numbers';

const Segment = ({
  segmentId,
  handleFilterChange,
  currentFilter,
  segmentCss,
  segmentIcon,
  segmentName,
  segmentText,
  numberOfUsersBelongingToSegment,
  percentageOfUsersInSegment,
  lang,
  hasAllCustomers,
}) => {
  const baseCss = 'cursor-pointer border p-4 rounded-none border-gray-300 hover:border-primary-base';
  return (
    <div
      className={cx(
        baseCss,
        currentFilter === segmentId ? segmentCss : 'border-gray-300',
        hasAllCustomers ? 'col-span-2' : 'col-span-2 md:col-span-1',
      )}
      onClick={() => {
        handleFilterChange(segmentId);
      }}
    >
      <div className="p-1 col-span-8 md:col-span-1">{segmentIcon}</div>
      <div className="flex flex-col col-span-8 pt-1 xl:col-span-7 xl:flex-row">
        <p className="text-base font-semibold whitespace-nowrap md:w-auto">{segmentName}</p>
        <div className="w-full flex xl:justify-end">
          <p className={cx('pt-1 text-sm font-semibold', lang === 'en' ? 'pr-2' : 'pr-2 pl-2')}>
            {lang === 'en' ? numberOfUsersBelongingToSegment : ArabicNumbers(numberOfUsersBelongingToSegment)}
            {!hasAllCustomers && (
              <span className={cx('font-light text-sm text-zyda-grey-70 pb-2', lang === 'en' ? 'pl-1' : 'pr-1')}>
                (
                {lang === 'en'
                  ? percentageOfUsersInSegment
                  : percentageOfUsersInSegment
                  ? ArabicNumbers(percentageOfUsersInSegment)
                  : ArabicNumbers(0)}
                %)
              </span>
            )}
          </p>
        </div>
      </div>
      <div className="col-span-8 md:col-start-2 md:col-end-8">
        <p className={cx('font-light text-sm mt-2', lang === 'en' ? 'pr-5' : 'pl-5')}>{segmentText}</p>
      </div>
    </div>
  );
};

export default Segment;
