import React from 'react';
import moment from 'moment';
import * as yup from 'yup';
import { Text } from 'components/service';
import {
  CAMPAIGN_TITLE_REQUIRED,
  CAMPAIGN_TYPE_REQUIRED,
  INACTIVITY_REQUIRED,
  INACTIVITY_CANT_MORE_THAN_LOOKBACKWINDOW,
  VALID_FOR_REQUIRED,
  START_DATE_REQUIRED,
  END_DATE_CANT_BE_BEFORE_START_DATE,
  END_DATE_CANT_BE_SAME_START_DATE,
  VOUCHER_AMOUNT_IS_REQUIRED,
  AMOUNT_MORE_THAN_0,
  ESTIMATE_REACH_ERROR,
  PERCENTAGE_MAX_100,
  TARGETED_SEGMENT_IS_REQUIRED,
  SMS_BODY_IS_REQUIRED,
  VALID_FOR_MUST_BE_POSITIVE,
  VALID_FOR_MUST_BE_AN_INTEGER,
} from 'constants/translations';
import { VOUCHER_TYPE, MINIMUM_REQUIREMENTS as VOUCHER_MINIMUM_REQUIREMENTS } from 'pages/marketing/constants';
import { CAMPAIGN_TYPES, LOOKBACK_WINDOW_KEYS, CAMPAIGN_OBJECT_KEYS, CAMPAIGN_LANGUAGES } from './constants';

const getDate = (amount, unit) =>
  moment()
    .subtract(amount, unit)
    .format('YYYY-MM-DD');

const lookbackWindowDates = {
  last_month: getDate(1, 'month'),
  last_3_months: getDate(3, 'month'),
  last_6_months: getDate(6, 'month'),
  last_year: getDate(1, 'year'),
};

export const createValidationSchemaCreation = yup.object().shape({
  title: yup.string().required(<Text value={CAMPAIGN_TITLE_REQUIRED} />),
  type: yup
    .mixed()
    .oneOf([CAMPAIGN_TYPES.RETENTION, CAMPAIGN_TYPES.SEGMENT_TARGETING])
    .required(<Text value={CAMPAIGN_TYPE_REQUIRED} />),
  inactivityPeriod: yup.number().when([CAMPAIGN_OBJECT_KEYS.STEP, CAMPAIGN_OBJECT_KEYS.TYPE], {
    is: (step, type) => step > 1 && type === CAMPAIGN_TYPES.RETENTION,
    then: yup.number().when(CAMPAIGN_OBJECT_KEYS.LOOKBACK_WINDOW, {
      is: value => value !== LOOKBACK_WINDOW_KEYS.ALL_CUSTOMERS,
      then: yup
        .number()
        .required(<Text value={INACTIVITY_REQUIRED} />)
        .min(1)
        .max(999999999)
        .when(
          CAMPAIGN_OBJECT_KEYS.LOOKBACK_WINDOW,
          (type, schema) =>
            type !== LOOKBACK_WINDOW_KEYS.ALL_CUSTOMERS &&
            schema.lessThan(
              moment().diff(lookbackWindowDates[type], 'days'),
              <Text value={INACTIVITY_CANT_MORE_THAN_LOOKBACKWINDOW} />,
            ),
        ),
      otherwise: yup
        .number()
        .required(<Text value={INACTIVITY_REQUIRED} />)
        .min(1),
    }),
  }),
  smsSendingLimit: yup.number().when([CAMPAIGN_OBJECT_KEYS.STEP, CAMPAIGN_OBJECT_KEYS.TYPE], {
    is: (step, type) => step > 1 && type === CAMPAIGN_TYPES.RETENTION,
    then: yup.number().min(1),
  }),
  targetedSegment: yup
    .number()
    .nullable(true)
    .when([CAMPAIGN_OBJECT_KEYS.STEP, CAMPAIGN_OBJECT_KEYS.TYPE], {
      is: (step, type) => step > 1 && type === CAMPAIGN_TYPES.SEGMENT_TARGETING,
      then: yup
        .number()
        .nullable(true)
        .required(<Text value={TARGETED_SEGMENT_IS_REQUIRED} />)
        .transform(value => (Number.isNaN(value) ? null : value)),
    }),
  estimateReach: yup.number().when(CAMPAIGN_OBJECT_KEYS.STEP, {
    is: step => step > 1,
    then: yup
      .number()
      .min(1)
      .moreThan(0, <Text value={ESTIMATE_REACH_ERROR} />),
  }),
  smsBody: yup.string().when(CAMPAIGN_OBJECT_KEYS.STEP, {
    is: step => step > 2,
    then: yup.string().required(<Text value={SMS_BODY_IS_REQUIRED} />),
  }),
  voucherAmount: yup.number().when([CAMPAIGN_OBJECT_KEYS.STEP, CAMPAIGN_OBJECT_KEYS.VOUCHER_TYPE], {
    is: (step, voucherType) => step > 2 && voucherType !== VOUCHER_TYPE.NO_VOUCHER,
    then: yup.number().when(CAMPAIGN_OBJECT_KEYS.VOUCHER_TYPE, {
      is: voucherType => voucherType !== VOUCHER_TYPE.FREE_DELIVERY,
      then: yup.number().when(CAMPAIGN_OBJECT_KEYS.VOUCHER_TYPE, {
        is: voucherType => voucherType === VOUCHER_TYPE.PERCENTAGE,
        then: yup
          .number()
          .required(<Text value={VOUCHER_AMOUNT_IS_REQUIRED} />)
          .min(1)
          .moreThan(0, <Text value={AMOUNT_MORE_THAN_0} />)
          .when(
            CAMPAIGN_OBJECT_KEYS.VOUCHER_TYPE,
            (type, schema) => type === VOUCHER_TYPE.PERCENTAGE && schema.max(100, <Text value={PERCENTAGE_MAX_100} />),
          ),
        otherwise: yup
          .number()
          .required(<Text value={VOUCHER_AMOUNT_IS_REQUIRED} />)
          .min(1)
          .moreThan(0, <Text value={AMOUNT_MORE_THAN_0} />),
      }),
      otherwise: yup.number(),
    }),
  }),
  validFor: yup.number().when([CAMPAIGN_OBJECT_KEYS.STEP, CAMPAIGN_OBJECT_KEYS.VOUCHER_TYPE], {
    is: (step, voucherType) => step > 2 && voucherType !== VOUCHER_TYPE.NO_VOUCHER,
    then: yup
      .number()
      .typeError(<Text value={VALID_FOR_MUST_BE_AN_INTEGER} />)
      .integer(<Text value={VALID_FOR_MUST_BE_AN_INTEGER} />)
      .positive(<Text value={VALID_FOR_MUST_BE_POSITIVE} />)
      .required(<Text value={VALID_FOR_REQUIRED} />)
      .min(1, <Text value={AMOUNT_MORE_THAN_0} />),
  }),
  startsAt: yup.date().when([CAMPAIGN_OBJECT_KEYS.STEP, CAMPAIGN_OBJECT_KEYS.TYPE], {
    is: (step, type) => step > 2 && type === CAMPAIGN_TYPES.RETENTION,
    then: yup.date().required(<Text value={START_DATE_REQUIRED} />),
  }),
  expiresAt: yup.date().when([CAMPAIGN_OBJECT_KEYS.STEP, CAMPAIGN_OBJECT_KEYS.TYPE], {
    is: (step, type) => step > 2 && type === CAMPAIGN_TYPES.RETENTION,
    then: yup
      .date()
      .min(yup.ref(CAMPAIGN_OBJECT_KEYS.STARTS_AT), <Text value={END_DATE_CANT_BE_BEFORE_START_DATE} />)
      .test({
        name: 'same',
        exclusive: false,
        params: {},
        message: <Text value={END_DATE_CANT_BE_SAME_START_DATE} />,
        test(value) {
          const startDate = moment(this.parent.startsAt).format('YYYY-MM-DD');
          const endDate = moment(value).format('YYYY-MM-DD');
          return !moment(startDate).isSame(moment(endDate));
        },
      })
      .nullable(true),
  }),
  voucherMinSubtotalAmount: yup.number().when([CAMPAIGN_OBJECT_KEYS.STEP, CAMPAIGN_OBJECT_KEYS.MINIMUM_REQUIREMENTS], {
    is: (step, minimumRequirements) =>
      step > 2 && minimumRequirements === VOUCHER_MINIMUM_REQUIREMENTS.MINIMUM_ORDER_AMOUNT,
    then: yup
      .number()
      .required(<Text value={AMOUNT_MORE_THAN_0} />)
      .min(1)
      .moreThan(0, <Text value={AMOUNT_MORE_THAN_0} />),
  }),
  voucherRedemptionLimitPerUser: yup
    .number()
    .when([CAMPAIGN_OBJECT_KEYS.STEP, CAMPAIGN_OBJECT_KEYS.VOUCHER_REDEMPTION_LIMIT_PER_USER_ENABLED], {
      is: (step, voucherRedemptionLimitPerUser) => step > 2 && voucherRedemptionLimitPerUser,
      then: yup
        .number()
        .required(<Text value={AMOUNT_MORE_THAN_0} />)
        .min(1)
        .moreThan(0, <Text value={AMOUNT_MORE_THAN_0} />),
    }),
});

export const editValidationSchemaCreation = yup.object().shape({
  startsAt: yup.date().required(<Text value={START_DATE_REQUIRED} />),
  expiresAt: yup
    .date()
    .min(yup.ref(CAMPAIGN_OBJECT_KEYS.STARTS_AT), <Text value={END_DATE_CANT_BE_BEFORE_START_DATE} />)
    .test({
      name: 'same',
      exclusive: false,
      params: {},
      message: <Text value={END_DATE_CANT_BE_SAME_START_DATE} />,
      test(value) {
        const startDate = moment(this.parent.startsAt).format('YYYY-MM-DD');
        const endDate = moment(value).format('YYYY-MM-DD');
        return !moment(startDate).isSame(moment(endDate));
      },
    })
    .nullable(),
});

export const initialValues = {
  step: 1,
  type: CAMPAIGN_TYPES.RETENTION,
  smsBody: '',
  lookbackWindow: LOOKBACK_WINDOW_KEYS.ALL_CUSTOMERS,
  estimateReach: 0,
  estimateReachWithSendingLimit: 0,
  startsAt: moment().format(),
  voucherType: VOUCHER_TYPE.PERCENTAGE,
  minimumRequirements: VOUCHER_MINIMUM_REQUIREMENTS.NONE,
  voucherRedemptionLimitPerUserEnabled: false,
  voucherRedemptionPerUserLimit: 0,
  campaignLanguage: CAMPAIGN_LANGUAGES.ENGLISH,
  isDraft: false,
  isCreate: false,
};
