import React from "react"
import * as translations from "constants/translations"
import { Text } from "components/service"
import * as yup from "yup"

export const validationSchema = yup.object().shape({
  gaTrackId: yup
    .string()
    .matches(
      /^(UA-\d+-\d+|G-\w+)$/i,
      <Text value={translations.ERROR_MSG_GOOGLE_ANALYTICS} />
    )
    .nullable(),
  fbadTrackId: yup
    .string()
    .matches(
      /^[\d]{15,16}$/i,
      <Text value={translations.ERROR_MSG_FACEBOOK_PIXELS} />
    )
    .nullable(),
  snapTrackId: yup
    .string()
    .matches(
      /^[a-z0-9]{8}-+[a-z0-9]{4}-+[a-z0-9]{4}-+[a-z0-9]{4}-+[a-z0-9]{12}$/,
      <Text value={translations.ERROR_MSG_SNAPCHAT_PIXELS} />
    )
    .nullable(),
  gtmTrackId: yup
    .string()
    .matches(
      /^[A-Z]{3}-+[A-Z0-9]{7}$/,
      <Text value={translations.ERROR_MSG_GTM} />
    )

})
