import {
  customers,
  analytics,
  salesAnalytics,
  behaviorBreakdown,
  bestSelling,
  geoAnalytics,
  menuOverview,
  itemTracking,
  createCampaign,
  socialmediatracking,
} from 'paths';
import { LAYOUT_ACCESS_MATRIX } from 'constants/translations';

export const STORE_ID_PATH_STRING = ':storeId';

export const ACCESS_MAP_KEYS = {
  SIDE: 'side',
  LAYOUT: 'layout',
  FEATURES: 'features',
};

export const FREE_PLAN_ACCESS_MAP = {
  [ACCESS_MAP_KEYS.SIDE]: [
    [
      customers,
      {
        isBlocked: true,
      },
    ],
    [
      analytics,
      {
        isBlocked: true,
      },
    ],
    [
      salesAnalytics,
      {
        isBlocked: true,
      },
    ],
    [
      behaviorBreakdown,
      {
        isBlocked: true,
      },
    ],
    [
      bestSelling,
      {
        isBlocked: true,
      },
    ],
    [
      geoAnalytics,
      {
        isBlocked: true,
      },
    ],
  ],
  [ACCESS_MAP_KEYS.LAYOUT]: [
    [
      customers,
      {
        isBlocked: true,
        featureTitle: LAYOUT_ACCESS_MATRIX.CUSTOMERS.featureTitle,
        featureDescription: LAYOUT_ACCESS_MATRIX.CUSTOMERS.featureDescription,
        featureListTitle: LAYOUT_ACCESS_MATRIX.CUSTOMERS.featureListTitle,
        featureList: LAYOUT_ACCESS_MATRIX.CUSTOMERS.featureList,
      },
    ],
    [
      salesAnalytics,
      {
        isBlocked: true,
        featureTitle: LAYOUT_ACCESS_MATRIX.SALES_ANALYTICS.featureTitle,
        featureDescription: LAYOUT_ACCESS_MATRIX.SALES_ANALYTICS.featureDescription,
        featureListTitle: LAYOUT_ACCESS_MATRIX.SALES_ANALYTICS.featureListTitle,
        featureList: LAYOUT_ACCESS_MATRIX.SALES_ANALYTICS.featureList,
      },
    ],
    [
      behaviorBreakdown,
      {
        isBlocked: true,
        featureTitle: LAYOUT_ACCESS_MATRIX.BEHAVIOR_BREAKDOWN.featureTitle,
        featureDescription: LAYOUT_ACCESS_MATRIX.BEHAVIOR_BREAKDOWN.featureDescription,
        featureListTitle: LAYOUT_ACCESS_MATRIX.BEHAVIOR_BREAKDOWN.featureListTitle,
        featureList: LAYOUT_ACCESS_MATRIX.BEHAVIOR_BREAKDOWN.featureList,
      },
    ],
    [
      bestSelling,
      {
        isBlocked: true,
        featureTitle: LAYOUT_ACCESS_MATRIX.BEST_SELLING.featureTitle,
        featureDescription: LAYOUT_ACCESS_MATRIX.BEST_SELLING.featureDescription,
        featureListTitle: LAYOUT_ACCESS_MATRIX.BEST_SELLING.featureListTitle,
        featureList: LAYOUT_ACCESS_MATRIX.BEST_SELLING.featureList,
      },
    ],
    [
      geoAnalytics,
      {
        isBlocked: true,
        featureTitle: LAYOUT_ACCESS_MATRIX.GEO_ANALYTICS.featureTitle,
        featureDescription: LAYOUT_ACCESS_MATRIX.GEO_ANALYTICS.featureDescription,
        featureListTitle: LAYOUT_ACCESS_MATRIX.GEO_ANALYTICS.featureListTitle,
        featureList: LAYOUT_ACCESS_MATRIX.GEO_ANALYTICS.featureList,
      },
    ],
  ],
  [ACCESS_MAP_KEYS.FEATURES]: [
    [
      menuOverview,
      {
        ITEM_TRACKING: {
          isBlocked: true,
        },
      },
    ],
    [
      itemTracking,
      {
        ITEM_TRACKING: {
          isBlocked: true,
        },
      },
    ],

    [
      createCampaign,
      {
        SEGMENT_TARGETING: {
          isBlocked: true,
        },
      },
    ],
    [
      socialmediatracking,
      {
        GTM: {
          isBlocked: true,
        },
        FACEBOOK_PIXEL: {
          isBlocked: true,
        },
        FACEBOOK_BUSINESS_MANAGER: {
          isBlocked: true,
        },
      },
    ],
  ],
};

export const STANDARD_PLAN_ACCESS_MAP = {
  [ACCESS_MAP_KEYS.SIDE]: [
    [
      customers,
      {
        isBlocked: true,
      },
    ],
    [
      analytics,
      {
        isBlocked: false,
      },
    ],
    [
      salesAnalytics,
      {
        isBlocked: false,
      },
    ],
    [
      behaviorBreakdown,
      {
        isBlocked: true,
      },
    ],
    [
      bestSelling,
      {
        isBlocked: true,
      },
    ],
    [
      geoAnalytics,
      {
        isBlocked: true,
      },
    ],
  ],
  [ACCESS_MAP_KEYS.LAYOUT]: [
    [
      customers,
      {
        isBlocked: true,
        featureTitle: LAYOUT_ACCESS_MATRIX.CUSTOMERS.featureTitle,
        featureDescription: LAYOUT_ACCESS_MATRIX.CUSTOMERS.featureDescription,
        featureListTitle: LAYOUT_ACCESS_MATRIX.CUSTOMERS.featureListTitle,
        featureList: LAYOUT_ACCESS_MATRIX.CUSTOMERS.featureList,
      },
    ],
    [
      behaviorBreakdown,
      {
        isBlocked: true,
        featureTitle: LAYOUT_ACCESS_MATRIX.BEHAVIOR_BREAKDOWN.featureTitle,
        featureDescription: LAYOUT_ACCESS_MATRIX.BEHAVIOR_BREAKDOWN.featureDescription,
        featureListTitle: LAYOUT_ACCESS_MATRIX.BEHAVIOR_BREAKDOWN.featureListTitle,
        featureList: LAYOUT_ACCESS_MATRIX.BEHAVIOR_BREAKDOWN.featureList,
      },
    ],
    [
      bestSelling,
      {
        isBlocked: true,
        featureTitle: LAYOUT_ACCESS_MATRIX.BEST_SELLING.featureTitle,
        featureDescription: LAYOUT_ACCESS_MATRIX.BEST_SELLING.featureDescription,
        featureListTitle: LAYOUT_ACCESS_MATRIX.BEST_SELLING.featureListTitle,
        featureList: LAYOUT_ACCESS_MATRIX.BEST_SELLING.featureList,
      },
    ],
    [
      geoAnalytics,
      {
        isBlocked: true,
        featureTitle: LAYOUT_ACCESS_MATRIX.GEO_ANALYTICS.featureTitle,
        featureDescription: LAYOUT_ACCESS_MATRIX.GEO_ANALYTICS.featureDescription,
        featureListTitle: LAYOUT_ACCESS_MATRIX.GEO_ANALYTICS.featureListTitle,
        featureList: LAYOUT_ACCESS_MATRIX.GEO_ANALYTICS.featureList,
      },
    ],
  ],
  [ACCESS_MAP_KEYS.FEATURES]: [
    [
      menuOverview,
      {
        ITEM_TRACKING: {
          isBlocked: true,
        },
      },
    ],
    [
      itemTracking,
      {
        ITEM_TRACKING: {
          isBlocked: true,
        },
      },
    ],
    [
      createCampaign,
      {
        SEGMENT_TARGETING: {
          isBlocked: true,
        },
      },
    ],
    [
      socialmediatracking,
      {
        GTM: {
          isBlocked: true,
        },
        FACEBOOK_PIXEL: {
          isBlocked: false,
        },
        FACEBOOK_BUSINESS_MANAGER: {
          isBlocked: false,
        },
      },
    ],
  ],
};

export const GROWTH_PLAN_ACCESS_MAP = {
  [ACCESS_MAP_KEYS.SIDE]: [
    [
      customers,
      {
        isBlocked: false,
      },
    ],
    [
      analytics,
      {
        isBlocked: false,
      },
    ],
    [
      salesAnalytics,
      {
        isBlocked: false,
      },
    ],
    [
      behaviorBreakdown,
      {
        isBlocked: false,
      },
    ],
    [
      bestSelling,
      {
        isBlocked: false,
      },
    ],
    [
      geoAnalytics,
      {
        isBlocked: false,
      },
    ],
  ],
  [ACCESS_MAP_KEYS.LAYOUT]: [
    [
      customers,
      {
        isBlocked: false,
        featureTitle: LAYOUT_ACCESS_MATRIX.CUSTOMERS.featureTitle,
        featureDescription: LAYOUT_ACCESS_MATRIX.CUSTOMERS.featureDescription,
        featureListTitle: LAYOUT_ACCESS_MATRIX.CUSTOMERS.featureListTitle,
        featureList: LAYOUT_ACCESS_MATRIX.CUSTOMERS.featureList,
      },
    ],
    [
      behaviorBreakdown,
      {
        isBlocked: false,
        featureTitle: LAYOUT_ACCESS_MATRIX.BEHAVIOR_BREAKDOWN.featureTitle,
        featureDescription: LAYOUT_ACCESS_MATRIX.BEHAVIOR_BREAKDOWN.featureDescription,
        featureListTitle: LAYOUT_ACCESS_MATRIX.BEHAVIOR_BREAKDOWN.featureListTitle,
        featureList: LAYOUT_ACCESS_MATRIX.BEHAVIOR_BREAKDOWN.featureList,
      },
    ],
    [
      bestSelling,
      {
        isBlocked: false,
        featureTitle: LAYOUT_ACCESS_MATRIX.BEST_SELLING.featureTitle,
        featureDescription: LAYOUT_ACCESS_MATRIX.BEST_SELLING.featureDescription,
        featureListTitle: LAYOUT_ACCESS_MATRIX.BEST_SELLING.featureListTitle,
        featureList: LAYOUT_ACCESS_MATRIX.BEST_SELLING.featureList,
      },
    ],
    [
      geoAnalytics,
      {
        isBlocked: false,
        featureTitle: LAYOUT_ACCESS_MATRIX.GEO_ANALYTICS.featureTitle,
        featureDescription: LAYOUT_ACCESS_MATRIX.GEO_ANALYTICS.featureDescription,
        featureListTitle: LAYOUT_ACCESS_MATRIX.GEO_ANALYTICS.featureListTitle,
        featureList: LAYOUT_ACCESS_MATRIX.GEO_ANALYTICS.featureList,
      },
    ],
  ],
  [ACCESS_MAP_KEYS.FEATURES]: [
    [
      menuOverview,
      {
        ITEM_TRACKING: {
          isBlocked: false,
        },
      },
    ],
    [
      itemTracking,
      {
        ITEM_TRACKING: {
          isBlocked: false,
        },
      },
    ],
    [
      createCampaign,
      {
        SEGMENT_TARGETING: {
          isBlocked: false,
        },
      },
    ],
    [
      socialmediatracking,
      {
        GTM: {
          isBlocked: false,
        },
        FACEBOOK_PIXEL: {
          isBlocked: false,
        },
        FACEBOOK_BUSINESS_MANAGER: {
          isBlocked: false,
        },
      },
    ],
  ],
};

export const PLAN_TYPES = {
  FREE: 'free',
  STANDARD: 'standard',
  GROWTH: 'growth',
};
