import React, { useContext, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { debounce } from 'lodash';
import cx from 'classnames';

import { context as userContext } from 'context/user';
import { context as localContext } from 'context/locale';
import { context as notificationsContext } from 'context/notifications';
import { context as customersContext } from 'context/customers';
import { ReactComponent as IconsInfo } from 'assets/blue-info.svg';
import { Field, Text } from 'components/service';
import { Stepper } from 'components/form/elements';
import { DropDown } from 'components/kit';
import { Label } from 'components/form/generic';
import { SEGMENT_TITLE_MAP } from 'constants/customers';
import SEGMENTS from 'common/schemas/segmentSchema';
import fuseSegmentContractWithSegmentStatic from 'common/utils/fuseSegmentContractWithSegmentStatic';
import {
  INACTVITY_PERIOD,
  INACTVITY_PERIOD_DESC,
  DAYS,
  EX_30,
  LOOK_BACK_WINDOW,
  LOOK_BACK_WINDOW_DESC,
  ALL_CUSTOMERS,
  LAST_YEAR,
  LAST_6_MONTHS,
  LAST_3_MONTHS,
  LAST_MONTH,
  MESSAGES_LIMIT,
  MESSAGES_LIMIT_DESC,
  INPUT_LABEL_SMS,
  EX_1000,
  NOTED_COOLDOWN_CAMPAIGN,
  SOMETHING_WENT_WRONG,
  MESSAGES_LIMIT_WARNING,
} from 'constants/translations';
import { CAMPAIGN_OBJECT_KEYS, LOOKBACK_WINDOW_KEYS, CAMPAIGN_TYPES } from './constants';
import { ESTIMATE_REACH } from './schemas';
import TargetedSegmentRecipient from './TargetedSegmentRecipient';
import CampaignDetailsSection from './CampaignDetailsSection';

const CampaignAudienceStep = ({
  setFieldValue,
  handleChange,
  type,
  lookbackWindow,
  inactivityPeriod,
  estimateReach,
  targetedSegment,
  creditsPerMessage,
  targetedSegmentError,
  smsSendingLimit,
  estimateReachWithSendingLimit,
  averageOrderValue,
  totalOrders,
}) => {
  const { selectedStore } = useContext(userContext);
  const { lang, translate } = useContext(localContext);
  const notifications = useContext(notificationsContext);
  const {
    segments: contextSegments,
    selectedSegment,
    setSegments,
    isWithinSegmentJobIntervals,
    setSegmentRetrievalDate,
  } = useContext(customersContext);

  const { loading: segmentLoading } = useQuery(SEGMENTS, {
    variables: {
      storeId: selectedStore.id,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: type === CAMPAIGN_TYPES.RETENTION || isWithinSegmentJobIntervals(),
    onCompleted: result => {
      const fusedSegments = {
        ...fuseSegmentContractWithSegmentStatic(
          result.segments.segments,
          result.segments.totalCustomerCount,
          contextSegments,
        ),
      };
      setSegments(fusedSegments);
      setSegmentRetrievalDate(new Date());
      const targetedSegmentId = targetedSegment || SEGMENT_TITLE_MAP.ALL_CUSTOMERS;
      setFieldValue(CAMPAIGN_OBJECT_KEYS.ESTIMATE_REACH, fusedSegments[targetedSegmentId]?.customersCount);
    },
  });

  const [getEstimateReach, { loading }] = useMutation(ESTIMATE_REACH, {
    variables: {
      storeId: selectedStore.id,
    },
    onError: () => {
      notifications.show(<Text value={SOMETHING_WENT_WRONG} />, 'error');
    },
  });

  const setEstimateReachWithSendingLimit = (estimateReachResponse, smsSendingLimitValue) => {
    if (estimateReachResponse) {
      let estimate = '--';
      if (smsSendingLimitValue)
        estimate = estimateReachResponse > smsSendingLimitValue ? smsSendingLimitValue : estimateReachResponse;
      else estimate = estimateReachResponse;
      setFieldValue(CAMPAIGN_OBJECT_KEYS.ESTIMATE_REACH_WITH_SENDING_LIMIT, estimate);
    }
  };

  const handleGetEstimateReach = async (typeParam, inactivityPeriodParam, lookbackWindowParam) => {
    try {
      const response = await getEstimateReach({
        variables: {
          type: typeParam,
          inactivityPeriod: inactivityPeriodParam,
          lookbackWindow: lookbackWindowParam,
        },
      });
      const estimateReachResponse = response?.data?.estimateReach?.estimateReach;
      if (estimateReachResponse >= 0) {
        setEstimateReachWithSendingLimit(estimateReachResponse, smsSendingLimit);
        setFieldValue(CAMPAIGN_OBJECT_KEYS.ESTIMATE_REACH, estimateReachResponse);
      }
    } catch {
      notifications.show(<Text value={SOMETHING_WENT_WRONG} />, 'error');
    }
  };

  const debouncedHandleGetEstimateReach = useMemo(
    () =>
      debounce(
        (typeParam, inactivityPeriodParam, lookbackWindowParam) =>
          handleGetEstimateReach(typeParam, inactivityPeriodParam, lookbackWindowParam),
        650,
      ),
    [type, lookbackWindow, smsSendingLimit],
  );

  const handleSetTargetedSegment = segment => {
    setFieldValue(CAMPAIGN_OBJECT_KEYS.TARGETED_SEGMENT, segment);
    setFieldValue(CAMPAIGN_OBJECT_KEYS.ESTIMATE_REACH, contextSegments[segment]?.customersCount);
    setFieldValue(CAMPAIGN_OBJECT_KEYS.CAMPAIGN_GOAL, 'No goal');
    if (creditsPerMessage)
      setFieldValue(
        CAMPAIGN_OBJECT_KEYS.ESTIMATED_CREDITS,
        contextSegments[segment]?.customersCount * creditsPerMessage,
      );
  };

  useEffect(() => {
    if (!Number.isNaN(targetedSegment) && type === CAMPAIGN_TYPES.SEGMENT_TARGETING && isWithinSegmentJobIntervals()) {
      setFieldValue(CAMPAIGN_OBJECT_KEYS.ESTIMATE_REACH, contextSegments[targetedSegment]?.customersCount);
      if (creditsPerMessage)
        setFieldValue(
          CAMPAIGN_OBJECT_KEYS.ESTIMATED_CREDITS,
          contextSegments[targetedSegment]?.customersCount * creditsPerMessage,
        );
    } else if (selectedSegment) {
      setFieldValue(CAMPAIGN_OBJECT_KEYS.ESTIMATE_REACH, contextSegments[selectedSegment]?.customersCount);
      if (creditsPerMessage)
        setFieldValue(
          CAMPAIGN_OBJECT_KEYS.ESTIMATED_CREDITS,
          contextSegments[targetedSegment]?.customersCount * creditsPerMessage,
        );
    }
  }, []);

  return type === CAMPAIGN_TYPES.RETENTION ? (
    <div className="flex flex-col-reverse md:flex-row pt-4 w-full pb-40">
      <div className={cx('w-full mt-6 md:w-8/12 md:mt-0', lang === 'en' ? 'md:pr-4' : 'md:pl-4')}>
        <div className="w-full mb-6">
          <Label title={<Text value={LOOK_BACK_WINDOW} />} subtitle={<Text value={LOOK_BACK_WINDOW_DESC} />}>
            <div style={{ direction: 'ltr' }}>
              <DropDown
                float="left"
                optionSelected={lookbackWindow}
                onSelect={option => {
                  setFieldValue(CAMPAIGN_OBJECT_KEYS.LOOKBACK_WINDOW, option);
                  option !== lookbackWindow && handleGetEstimateReach(type, parseInt(inactivityPeriod) || 0, option);
                }}
                data={[
                  {
                    id: LOOKBACK_WINDOW_KEYS.ALL_CUSTOMERS,
                    title: <Text value={ALL_CUSTOMERS} />,
                  },
                  {
                    id: LOOKBACK_WINDOW_KEYS.LAST_YEAR,
                    title: <Text value={LAST_YEAR} />,
                  },
                  {
                    id: LOOKBACK_WINDOW_KEYS.LAST_6_MONTHS,
                    title: <Text value={LAST_6_MONTHS} />,
                  },
                  {
                    id: LOOKBACK_WINDOW_KEYS.LAST_3_MONTHS,
                    title: <Text value={LAST_3_MONTHS} />,
                  },
                  {
                    id: LOOKBACK_WINDOW_KEYS.LAST_MONTH,
                    title: <Text value={LAST_MONTH} />,
                  },
                ]}
                icon="keyboard_arrow_down"
                position={lang === 'ar' ? 'right' : 'left'}
                width="w-40"
                wFull
              />
            </div>
          </Label>
        </div>
        <div className="w-full mb-6">
          <Label
            title={<Text value={INACTVITY_PERIOD} />}
            subtitle={<Text className="text-gray-600" value={INACTVITY_PERIOD_DESC} />}
          >
            <Field
              name={CAMPAIGN_OBJECT_KEYS.INACTIVITY_PERIOD}
              type="number"
              left={<Text value={DAYS} className="capitalize" />}
              min={0}
              max={999999999}
              step={1}
              placeholder={translate(EX_30)}
              onChange={e => {
                handleChange(e);
                if (e.target.value) debouncedHandleGetEstimateReach(type, parseInt(e.target.value), lookbackWindow);
              }}
              component={Stepper}
              width="64"
            />
          </Label>
        </div>
        <div className="w-full mb-12">
          <Label
            title={<Text value={MESSAGES_LIMIT} />}
            subtitle={
              <>
                <Text className="text-gray-600" value={MESSAGES_LIMIT_DESC} />
                <Text className="text-gray-600 font-semibold" value={MESSAGES_LIMIT_WARNING} />
              </>
            }
          >
            <Field
              name={CAMPAIGN_OBJECT_KEYS.SMS_SENDING_LIMIT}
              type="number"
              left={<Text value={INPUT_LABEL_SMS} className="whitespace-nowrap" />}
              min={0}
              step={1}
              placeholder={translate(EX_1000)}
              component={Stepper}
              width="64"
              onChange={e => {
                handleChange(e);
                setEstimateReachWithSendingLimit(estimateReach, e.target.value);
              }}
            />
          </Label>
        </div>
        <div className="flex items-center text-gray-600 p-4 border border-blue-600 bg-primary-50">
          <div className={cx('pb-4', lang === 'en' ? 'pr-3' : 'pl-3')}>
            <IconsInfo className="inline" />
          </div>
          <Text value={NOTED_COOLDOWN_CAMPAIGN} className="inline" />
        </div>
      </div>
      <div className="justify-end whitespace-no-wrap w-full sticky top-0 bg-white pt-4 md:pt-0 md:w-4/12 md:relative">
        <CampaignDetailsSection
          loading={loading}
          estimateReach={estimateReachWithSendingLimit}
          lang={lang}
          averageOrderValue={averageOrderValue}
          totalOrders={totalOrders}
        />
      </div>
    </div>
  ) : (
    <div className="pb-32 md:pb-40 lg:pb-32">
      <TargetedSegmentRecipient
        setTargetedSegment={handleSetTargetedSegment}
        currentSelectedSegment={targetedSegment}
        loading={segmentLoading}
        targetedSegmentError={targetedSegmentError}
      />
    </div>
  );
};

CampaignAudienceStep.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf([CAMPAIGN_TYPES.RETENTION, CAMPAIGN_TYPES.SEGMENT_TARGETING]),
  lookbackWindow: PropTypes.oneOf([
    LOOKBACK_WINDOW_KEYS.LAST_MONTH,
    LOOKBACK_WINDOW_KEYS.LAST_YEAR,
    LOOKBACK_WINDOW_KEYS.ALL_CUSTOMERS,
    LOOKBACK_WINDOW_KEYS.LAST_3_MONTHS,
    LOOKBACK_WINDOW_KEYS.LAST_6_MONTHS,
  ]).isRequired,
  inactivityPeriod: PropTypes.number,
  estimateReach: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  targetedSegment: PropTypes.oneOf([
    SEGMENT_TITLE_MAP.ALL_CUSTOMERS,
    SEGMENT_TITLE_MAP.CHAMPIONS,
    SEGMENT_TITLE_MAP.LOYAL,
    SEGMENT_TITLE_MAP.PROMISING,
    SEGMENT_TITLE_MAP.NEW,
    SEGMENT_TITLE_MAP.LOW_VALUE,
    SEGMENT_TITLE_MAP.NEEDS_ATTENTION,
  ]),
  creditsPerMessage: PropTypes.number,
  targetedSegmentError: PropTypes.shape({ props: PropTypes.shape({ value: PropTypes.arrayOf(PropTypes.string) }) }),
  smsSendingLimit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  estimateReachWithSendingLimit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  averageOrderValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  totalOrders: PropTypes.number,
};

export default CampaignAudienceStep;
