import React, { useContext } from 'react';
import moment from 'moment';
import cx from 'classnames';
import ReactTooltip from 'react-tooltip';

import { useQueryState } from 'hooks';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import DATE_RANGES from 'constants/date';
import { breadcrumbs, ANALYTICS_TOOLTIP_INFORMATION } from 'constants/translations';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { DateRange } from 'components/common/analytics';
import { Text } from 'components/service';
import { parseRange, stringifyRange, getRange, toRangeVariable } from 'utils/date';
import { ReactComponent as AlertCircle } from 'assets/Icons_Warning.svg';
import GeoBranches from '../GeoBranches';
import momentLanguage from '../../utils';
import { BRANCHES_DELIVERY_AREAS_LINKS, BRANCHES_DELIVERY_ZONES_LINKS } from 'constants/helperLinks';

const GeoAnalytics = () => {
  const notifications = useContext(notificationsContext);
  const { selectedStore, settings } = useContext(userContext);
  const { direction, lang } = useContext(localeContext);
  const resettableStates = { page: 1 };
  const [query, setQuery] = useQueryState(
    {
      type_date: DATE_RANGES.THIRTY_DAYS,
      ...resettableStates,
      range: stringifyRange(getRange(DATE_RANGES.THIRTY_DAYS)),
    },
    {
      page: parseInt,
    },
  );

  const range = parseRange(query.range);
  const rangeVariable = toRangeVariable(range);

  const length = breadcrumbs.GEO_ANALYTICS[0].length - 1;

  moment.locale(momentLanguage(lang));

  return (
    <Layout
      top={
        <Breadcrumbs
          helperLinks={settings.useDeliveryzonesMs ? BRANCHES_DELIVERY_ZONES_LINKS : BRANCHES_DELIVERY_AREAS_LINKS}
          path={settings.useDeliveryzonesMs ? breadcrumbs.GEO_ANALYTICS_ZONES : breadcrumbs.GEO_ANALYTICS}
          customTitle={
            <div className={cx('flex', lang === 'en' ? 'flex-row' : 'flex-row-reverse')}>
              <Text
                className="text-2xl font-bold text-black"
                value={
                  settings.useDeliveryzonesMs
                    ? [breadcrumbs.GEO_ANALYTICS_ZONES[0][length], breadcrumbs.GEO_ANALYTICS_ZONES[1][length]]
                    : [breadcrumbs.GEO_ANALYTICS[0][length], breadcrumbs.GEO_ANALYTICS[1][length]]
                }
              />
              <div>
                <AlertCircle
                  data-tip
                  data-for="geoAnalyticsTooltip"
                  className="text-gray-600 fill-current inline mr-2 ml-1 mt-1"
                  style={{ transform: 'scale(0.75)' }}
                />
                <ReactTooltip place="bottom" type="dark" effect="solid" id="geoAnalyticsTooltip">
                  <ul className="list-disc" dir={lang === 'en' ? 'ltr' : 'rtl'}>
                    {ANALYTICS_TOOLTIP_INFORMATION.map(singleInformationLine => (
                      <li>
                        <Text value={singleInformationLine} />
                      </li>
                    ))}
                  </ul>
                </ReactTooltip>
              </div>
            </div>
          }
          right={
            <DateRange
              queryTypeDate={query.type_date}
              range={range}
              selectedStoreCreatedAt={selectedStore.createdAt}
              notifications={notifications}
              setQuery={setQuery}
              direction={direction}
            />
          }
        />
      }
    >
      <div className="pb-20" style={{ direction }}>
        <GeoBranches range={rangeVariable} setQuery={setQuery} page={query.page} />
      </div>
    </Layout>
  );
};

export default GeoAnalytics;
