import React from "react"
import { Box, Button, CatalogBox, ToggleButton } from "components/kit"
import * as translations from "constants/translations"
import { Text } from "components/service"

export default ({
  title,
  subtitle,
  description,
  icon,
  name,
  body,
  innerBody,
  buttonSize,
  btnTxtColor,
  value,
  onChange,
  actionName,
  testId,
  toggle,
  zoneToggle
}) => {
  return (
    <CatalogBox
      title={title}
      subtitle={subtitle}
      description={description}
      icon={icon}
      body={body}
      innerBody={innerBody}
      zoneToggle={zoneToggle}
      action={
        toggle ? (
          <ToggleButton
            name={name}
            value={value}
            onChange={() => onChange({ target: { name, value: !value } })}
          />
        ) : (
          <Button
            data-testid={testId}
            type="button"
            kind="tertiary"
            weight="thin"
            size={buttonSize}
            textColor={btnTxtColor}
            onClick={() => onChange({ target: { name, value: !value } })}
          >
            {!value ? (
              actionName ? (
                actionName[0]
              ) : (
                <Text value={translations.ENABLE} />
              )
            ) : actionName ? (
              actionName[1]
            ) : (
              <Text value={translations.DISABLE} />
            )}
          </Button>
        )
      }
    />
  )
}
