import React, { useContext } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { Field, Text } from 'components/service';
import { Formik, Form } from 'formik';
import * as paths from 'paths.js';
import * as R from 'ramda';
import cx from 'classnames';

import * as translations from 'constants/translations';
import { context as localeContext } from 'context/locale';
import { context as notificationsContext } from 'context/notifications';
import { context as userContext } from 'context/user';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { Row, Label, Container, Footer, Section } from 'components/form/generic';
import { InfoCheckbox, RadioList, Stepper } from 'components/form/elements';
import { Button, Box, Spinner } from 'components/kit';
import { ORDER_SCHEDULING_LINKS } from 'constants/helperLinks';
import * as data from './data';
import * as schemas from './schemas';
import * as utils from './utils';
import { maxAcceptanceNotificationType, timeIntervalType } from './constants';

export default () => {
  const { selectedStore } = useContext(userContext);
  const { lang, direction } = useContext(localeContext);
  const notifications = useContext(notificationsContext);

  const { data: dataSettings, loading } = useQuery(schemas.SETTINGS, {
    variables: { storeId: selectedStore.id },
  });

  const minimumNumber = 0;
  const validateInputGreaterThanZero = n => n >= 0;

  const [updateSettings, { loading: isSettingsUpdating }] = useMutation(schemas.UPDATE_SETTINGS, {
    onError: err => {
      let body = err.graphQLErrors[0].extensions.exception.body;
      let map = {
        schedule_interval: 'Time Slot Interval',
        schedule_max_time_period: 'Max schedule time',
        one_or_more_branch: 'Store',
        order_accept_max_threshold_minutes: 'Max Acceptance Threshold',
        default_product_prep_time: 'Default Preparation Time',
      };
      for (let key of Object.keys(body)) {
        const prefix = map[key] ? map[key] : '';
        notifications.show(`${prefix} ${body[key][0]}`, 'error');
      }
    },
    onCompleted: data => {
      getUpdatedRestaurantSettings();
      notifications.show(<Text value={translations.ORDER_MANAGEMENT_UPDATED} />);
    },
  });

  const [getUpdatedRestaurantSettings] = useLazyQuery(schemas.RESTAURANT, {
    variables: {
      storeId: selectedStore.id,
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Layout
      top={<Breadcrumbs helperLinks={ORDER_SCHEDULING_LINKS} path={translations.breadcrumbs.SETUP_ORDERS_MANAGEMENT} />}
    >
      <div className={cx('pb-6 flex', lang === 'ar' && 'flex-row-reverse')}>
        <div className="w-full md:w-2/3" style={{ direction }}>
          {!dataSettings ? (
            <Spinner />
          ) : (
            <Formik
              validationSchema={data.validationSchema}
              initialValues={R.mergeDeepRight(
                data.initialValues,
                dataSettings && {
                  later: dataSettings.settings.scheduleOrdersEnabled,
                  timeInterval: utils.scheduleIntervalInitialConverts(dataSettings.settings.scheduleInterval),
                  maxScheduleTime: utils.maxScheduleInitialConverts(dataSettings.settings.scheduleMaxTimePeriod),
                  orderAcceptMaxThresholdMinutes: utils.minsInitialConverts(
                    dataSettings.settings.orderAcceptMaxThresholdMinutes,
                  ),
                  maxAcceptanceNotificationType: dataSettings.settings.maxAcceptanceThresholdType,
                  defaultProductPrepTime: utils.minsInitialConverts(dataSettings.settings.defaultProductPrepTime),
                  isAutoAccept: dataSettings.settings.isAutoAccept,
                  sendAsGiftEnabled: dataSettings.settings.sendAsGiftEnabled,
                },
              )}
              onSubmit={async values => {
                let variables = values.later
                  ? {
                      storeId: selectedStore.id,
                      later: values.later,
                      scheduleInterval: utils.convertToMins(values.timeInterval),
                      scheduleMaxTimePeriod: utils.convertToHours(values.maxScheduleTime),
                      orderAcceptMaxThresholdMinutes: utils.convertToMins(values.orderAcceptMaxThresholdMinutes),
                      maxAcceptanceThresholdType: values.maxAcceptanceNotificationType,
                      defaultProductPrepTime: utils.convertToMins(values.defaultProductPrepTime),
                      isAutoAccept: values.isAutoAccept,
                      sendAsGiftEnabled: values.sendAsGiftEnabled,
                    }
                  : {
                      storeId: selectedStore.id,
                      later: values.later,
                      orderAcceptMaxThresholdMinutes: utils.convertToMins(values.orderAcceptMaxThresholdMinutes),
                      maxAcceptanceThresholdType: values.maxAcceptanceNotificationType,
                      defaultProductPrepTime: utils.convertToMins(values.defaultProductPrepTime),
                      isAutoAccept: values.isAutoAccept,
                      sendAsGiftEnabled: values.sendAsGiftEnabled,
                    };

                await updateSettings({ variables });
              }}
            >
              {({ values, handleChange }) => (
                <Form>
                  <Box
                    title={<Text value={translations.SCHEDULING} />}
                    body={
                      <div className="px-3 pb-3">
                        <Container>
                          <Row>
                            <Field
                              title={<Text value={translations.LATER} />}
                              subtitle={<Text value={translations.LATER_ALLOW_CUSTOMERS} className="text-sm" />}
                              name="later"
                              btnTxtColor="text-primary-base"
                              component={InfoCheckbox}
                              testId="scheduling-disable-btn"
                              body={
                                values.later && (
                                  <div className="flex flex-wrap -mx-1 px-3">
                                    <div className="w-full md:w-1/2 px-1">
                                      <Label
                                        title={<Text value={translations.TIME_SLOT_INTERVAL} />}
                                        subtitle={<Text value={translations.TIME_INTERVAL_BETWEEN} />}
                                      >
                                        <Field
                                          name="timeInterval"
                                          testId="scheduling-timeInterval"
                                          options={[
                                            {
                                              id: timeIntervalType.MINUTES,
                                              title: <Text value={translations.MINUTES} />,
                                            },
                                            {
                                              id: timeIntervalType.HOURS,
                                              title: <Text value={translations.HOURS} />,
                                            },
                                          ]}
                                          dropdown
                                          step={1}
                                          component={Stepper}
                                          testId_openDropDown="scheduling-open-slots"
                                          testId_selectedData="scheduling-select-slot"
                                          testId_listData="scheduling-list-slots"
                                          testId_stepDown="scheduling-slots-stepDown"
                                          testId_stepUp="scheduling-slots-stepUp"
                                        />
                                      </Label>
                                    </div>
                                    <div className="w-full md:w-1/2 px-1">
                                      <Label
                                        title={<Text value={translations.MAX_SCHEDULE_TIME} />}
                                        subtitle={<Text value={translations.MAX_TIME_ORDER} />}
                                      >
                                        <Field
                                          name="maxScheduleTime"
                                          testId="scheduling-maxScheduleTime"
                                          step={1}
                                          options={[
                                            {
                                              id: timeIntervalType.MINUTES,
                                              title: <Text value={translations.MINUTES} />,
                                            },
                                            {
                                              id: timeIntervalType.HOURS,
                                              title: <Text value={translations.HOURS} />,
                                            },
                                            {
                                              id: timeIntervalType.DAYS,
                                              title: <Text value={translations.DAYS} />,
                                            },
                                          ]}
                                          dropdown
                                          min={minimumNumber}
                                          validation={validateInputGreaterThanZero}
                                          component={Stepper}
                                          testId_openDropDown="scheduling-open-maxtime"
                                          testId_selectedData="scheduling-select-maxtime"
                                          testId_listData="scheduling-list-maxtime"
                                          testId_stepDown="scheduling-maxtime-stepDown"
                                          testId_stepUp="scheduling-maxtime-stepUp"
                                        />
                                      </Label>
                                    </div>
                                  </div>
                                )
                              }
                            />
                          </Row>
                        </Container>
                      </div>
                    }
                  />
                  <div className="mt-4">
                    <Section
                      bottomOffset={0}
                      title={<Text value={translations.TIMING} />}
                      description={<Text value={translations.TIMING_SUBTITLE} />}
                    >
                      <div className="w-full md:w-1/2 px-1">
                        <Label title={<Text value={translations.PREPARATION_TIME_FIELD_TITLE} />}>
                          <Field
                            name="defaultProductPrepTime"
                            testId="scheduling-defaultProductPrepTime"
                            testId_openDropDown="scheduling-open-ProductPrepTimes"
                            testId_selectedData="scheduling-select-ProductPrepTimes"
                            testId_listData="scheduling-list-ProductPrepTimes"
                            testId_stepDown="scheduling-ProductPrepTime-stepDown"
                            testId_stepUp="scheduling-ProductPrepTime-stepUp"
                            options={[
                              {
                                id: timeIntervalType.MINUTES,
                                title: <Text value={translations.MINUTES} />,
                              },
                              {
                                id: timeIntervalType.HOURS,
                                title: <Text value={translations.HOURS} />,
                              },
                              {
                                id: timeIntervalType.DAYS,
                                title: <Text value={translations.DAYS} />,
                              },
                            ]}
                            dropdown
                            step={1}
                            min={minimumNumber}
                            validation={validateInputGreaterThanZero}
                            component={Stepper}
                          />
                        </Label>
                      </div>
                    </Section>
                  </div>
                  <div className="mt-4">
                    <Section
                      bottomOffset={0}
                      title={<Text value={translations.ACCEPTANCE_THRESHOLD_TITLE} />}
                      description={<Text value={translations.ACCEPTANCE_THRESHOLD_DESCRIPTION} />}
                    >
                      <div className="w-full md:w-1/2 px-1">
                        <Field
                          name="orderAcceptMaxThresholdMinutes"
                          testId="scheduling-orderAcceptMaxThresholdMinutes"
                          testId_openDropDown="scheduling-open-orderAcceptMaxThresholdMinutes"
                          testId_selectedData="scheduling-select-orderAcceptMaxThresholdMinutes"
                          testId_listData="scheduling-list-orderAcceptMaxThresholdMinutes"
                          testId_stepDown="scheduling-orderAcceptMaxThresholdMinutes-stepDown"
                          testId_stepUp="scheduling-orderAcceptMaxThresholdMinutes-stepUp"
                          options={[
                            {
                              id: timeIntervalType.MINUTES,
                              title: <Text value={translations.MINUTES} />,
                            },
                            {
                              id: timeIntervalType.HOURS,
                              title: <Text value={translations.HOURS} />,
                            },
                            {
                              id: timeIntervalType.DAYS,
                              title: <Text value={translations.DAYS} />,
                            },
                          ]}
                          dropdown
                          validation={validateInputGreaterThanZero}
                          min={minimumNumber}
                          step={1}
                          component={Stepper}
                        />
                      </div>
                      <Row className="mt-2 -mx-3">
                        <Field
                          name="maxAcceptanceNotificationType"
                          component={RadioList}
                          items={[
                            {
                              title: <Text value={translations.EMAIL_NOTIFICATION} />,
                              value: maxAcceptanceNotificationType.EMAIL,
                            },
                            {
                              title: <Text value={translations.EMAIL_AND_SMS_NOTIFICATION} />,
                              value: maxAcceptanceNotificationType.EMAIL_AND_SMS,
                            },
                          ]}
                        />
                      </Row>
                    </Section>
                  </div>
                  <div className="mt-4">
                    <Container>
                      <Row>
                        <Field
                          title={<Text value={translations.GIFT_ORDERING} />}
                          subtitle={<Text value={translations.GIFT_ORDERING_DESCRIPTION} className="text-sm" />}
                          name="sendAsGiftEnabled"
                          btnTxtColor="text-primary-base"
                          component={InfoCheckbox}
                          testId="scheduling-sendAsGiftEnabled"
                        />
                      </Row>
                    </Container>
                  </div>
                  <div className="mt-4">
                    <Box
                      title={<Text value={translations.WORKFLOW} />}
                      body={
                        <div className="px-3 pb-3">
                          <Container>
                            <Row>
                              <Field
                                title={<Text value={translations.AUTO_ACCEPT_ORDER} />}
                                subtitle={<Text value={translations.THIS_ALLOW_ORDER_AUTOMATIC} className="text-sm" />}
                                name="isAutoAccept"
                                btnTxtColor="text-primary-base"
                                testId="scheduling-isAutoAccept"
                                component={InfoCheckbox}
                                onChange={e => {
                                  if (!values.isAutoAccept && !!selectedStore.restaurantCourierSetting.length) {
                                    notifications.show(
                                      <Text value={translations.AUTO_ACCEPT_NOT_AVAILABLE} />,
                                      'warning',
                                    );
                                  } else {
                                    handleChange(e);
                                  }
                                }}
                              />
                            </Row>
                          </Container>
                        </div>
                      }
                    />
                  </div>
                  <Footer>
                    <Button kind="primary" isSpinning={isSettingsUpdating} data-testid="scheduling-save-btn">
                      <Text value={translations.SAVE_CHANGE} />
                    </Button>
                  </Footer>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </Layout>
  );
};
