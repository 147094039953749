import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import * as translations from 'constants/translations';
import { Text } from 'components/service';
import { Button } from 'components/kit';
import { context as localeContext } from 'context/locale';
import { useMobile, useTablet } from 'hooks';
import Stepper from './SecondaryStepper';

const SecondaryWizard = ({
  children,
  updateStep,
  setErrors,
  handleCancel,
  customInitialAction = {
    hasCustomInitialAction: false,
    hasCustomTitle: false,
    title: ['', ''],
    initialAction: () => {
      // Do Nothing
    },
  },
  customFinalAction = {
    hasCustomFinalAction: false,
    isDisabled: false,
    title: ['', ''],
    finalAction: () => {
      // Do Nothing
    },
  },
}) => {
  const { direction, lang } = useContext(localeContext);

  const [currentStepNumber, setCurrentStep] = useState(1);

  const isMobile = useMobile();
  const isTablet = useTablet();

  const stepArray = children;
  const isFinalStep = currentStepNumber === stepArray.length;
  const isInitialStep = currentStepNumber === 1;

  const proceedToNextStep = newStep => {
    const newStepTemp = newStep + 1;
    if (newStepTemp > 0 && newStepTemp <= stepArray.length) {
      setCurrentStep(newStepTemp);
      updateStep(newStepTemp);
    }
  };

  const validateStepForm = (currentStep, newStep) => {
    const currentStepProps = stepArray[currentStep]?.props;
    if (currentStepProps?.validateForm) {
      currentStepProps.validateForm().then(result => {
        if (Object.keys(result).length === 0) {
          proceedToNextStep(newStep);
        } else {
          currentStepProps?.step?.fields &&
            currentStepProps.step.fields.forEach(field => {
              currentStepProps.setFieldTouched(field.name, true);
            });
        }
      });
    }
    if (customInitialAction.hasCustomInitialAction) customInitialAction.initialAction();
  };

  const submitForm = currentStep => {
    stepArray[currentStep].props.submitForm();
  };

  const handleNextClick = (addedValue, isFinish) => {
    let newStep = currentStepNumber;
    const currentStep = newStep - 1;
    if (isFinish) {
      customFinalAction.hasCustomFinalAction ? customFinalAction.finalAction() : submitForm(currentStep);
    } else {
      newStep = currentStep + addedValue;
      validateStepForm(currentStep, newStep);
      setCurrentStep(newStep);
    }
  };

  const handleBackClick = () => {
    let newStep = currentStepNumber;
    newStep--;
    setCurrentStep(newStep);
    updateStep(newStep);
    setErrors({});
  };

  return (
    <div className="flex flex-col" style={{ direction }}>
      <div className="flex-grow container horizontal">
        <Stepper steps={stepArray} currentStepNumber={currentStepNumber} />
      </div>

      <div
        className={cx(
          'flex flex-row-reverse justify-between fixed w-full border-t border-gray-300 bg-white py-4 -mx-4 xl:-mx-6 bottom-0',
          lang === 'en' ? 'pr-24 pl-4' : 'pl-24 pr-4',
        )}
        style={{ width: isMobile ? '100%' : 'calc(100% - 16rem)' }}
      >
        <div className="flex flex-row-reverse">
          <Button
            kind="primary"
            type="button"
            disabled={isFinalStep && customFinalAction?.isDisabled}
            onClick={() => handleNextClick(1, isFinalStep)}
          >
            <Text
              value={
                isFinalStep
                  ? customFinalAction.hasCustomFinalAction
                    ? customFinalAction.title
                    : translations.FINISH
                  : isInitialStep
                  ? customInitialAction.hasCustomTitle
                    ? customInitialAction.title
                    : translations.NEXT
                  : translations.NEXT
              }
            />
          </Button>
          {currentStepNumber !== 1 && (
            <div className="mx-2">
              <Button kind="secondary" type="button" onClick={handleBackClick}>
                <Text value={translations.BACK} />
              </Button>
            </div>
          )}
        </div>
        {!isTablet && (
          <Button kind="tertiaryLink" type="button" onClick={() => handleCancel()}>
            <Text value={translations.CANCEL} />
          </Button>
        )}
      </div>
    </div>
  );
};

SecondaryWizard.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  updateStep: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  customFinalAction: PropTypes.shape({
    hasCustomFinalAction: PropTypes.bool,
    title: PropTypes.arrayOf(PropTypes.string),
    isDisabled: PropTypes.bool,
    finalAction: PropTypes.func,
  }),
  handleCancel: PropTypes.func.isRequired,
  customInitialAction: PropTypes.shape({
    hasCustomInitialAction: PropTypes.bool,
    initialAction: PropTypes.func,
  }),
};

export default SecondaryWizard;
