import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import { context as localContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import { ReactComponent as AlertCircle } from 'assets/Icons_Warning.svg';
import BackgroundIphone from 'assets/mobileiphone-preview.svg';
import { Button, Spinner } from 'components/kit';
import { Text } from 'components/service';
import {
  ESTIMATED_RESULTS,
  SMS_CREDITS,
  ESTIMATED_CREDITS,
  TARGETED_SEGMENT,
  SOMETHING_WENT_WRONG,
  CUSTOMERS,
  SMS_CREDITS_WITHIN_RANGE_BUT_LESS_THAN_REQUIRED_WARNING,
  SMS_CREDITS_BELOW_MINIMUM,
  FAILED_TO_LOAD_CAMPAIGN_SMS_PREVIEW,
  SUMMARY,
} from 'constants/translations';
import { SEGMENT_TITLE_MAP, initialSegments } from 'constants/customers';
import { CAMPAIGN_PREVIEW } from './schemas';
import { CAMPAIGN_STATES, CREDIT_BALANCE_STATE, CAMPAIGN_TYPES } from './constants';
import { prepareCampaignObject, calculateCreditBalanceRatio } from './utils';

const CampaignReviewStep = ({ targetedSegment = '-', smsCredits, values, handleAddSmsCredits }) => {
  const { translate, direction } = useContext(localContext);
  const { selectedStore } = useContext(userContext);

  const notifications = useContext(notificationsContext);
  const [generatedSmsBody, setGeneratedSmsBody] = useState('');
  const [resaurantSenderId, setResaurantSenderId] = useState('');
  const { type, estimatedCredits, estimateReachWithSendingLimit, estimateReach } = values;
  const creditBalanceState = calculateCreditBalanceRatio(smsCredits, estimatedCredits);

  const targetedSegmentTitle =
    targetedSegment === '-' || type === CAMPAIGN_TYPES.RETENTION
      ? ['-', '-']
      : [initialSegments[targetedSegment].titleEn, initialSegments[targetedSegment].titleAr];

  const [previewCampaign, { error, loading }] = useMutation(CAMPAIGN_PREVIEW, {
    variables: {
      restaurantId: selectedStore.id,
    },
    onCompleted: result => {
      setGeneratedSmsBody(result?.previewCampaign.generateSmsBody);
      setResaurantSenderId(result?.previewCampaign.restaurantSenderId);
    },
    onError: () => {
      notifications.show(<Text value={SOMETHING_WENT_WRONG} />, 'error');
    },
  });

  useEffect(() => {
    const campaignObject = prepareCampaignObject(values, CAMPAIGN_STATES.ACTIVE);
    previewCampaign({
      variables: {
        ...campaignObject,
      },
    });
  }, []);

  return (
    <div className="flex flex-col sm:flex-row w-full mt-6 pt-10 mb-24">
      <div className="flex flex-col w-full md:w-1/2">
        <Text className="text-xl font-semibold mb-6" value={SUMMARY} />
        <div className="flex flex-row justify-between mb-12">
          <div className="flex flex-col justify-between">
            <div className="mb-4">
              <Text className="text-sm text-gray-600" value={TARGETED_SEGMENT} />
              <Text className="text-lg" value={targetedSegmentTitle} />
            </div>
            <div>
              <Text className="text-sm text-gray-600" value={SMS_CREDITS} />
              <p className="text-lg">{smsCredits}</p>
            </div>
          </div>
          <div className="flex flex-col justify-between">
            <div className="mb-4">
              <Text className="text-sm text-gray-600" value={ESTIMATED_RESULTS} />
              <p className="text-lg">
                {type === CAMPAIGN_TYPES.RETENTION ? estimateReachWithSendingLimit : estimateReach}
                <span className="pl-2">{translate(CUSTOMERS)}</span>
              </p>
            </div>
            <div>
              <Text className="text-sm text-gray-600" value={ESTIMATED_CREDITS} />
              <p className="text-lg">{estimatedCredits}</p>
            </div>
          </div>
        </div>
        {creditBalanceState === CREDIT_BALANCE_STATE.MEETS_MINIMUM_BELOW_REQUIRED && (
          <div className="flex flex-row border border-yellow-500 bg-yellow-100 p-3">
            <div className="pt-1">
              <AlertCircle
                className="text-zyda-orange-200 fill-current inline mr-2"
                style={{ transform: 'scale(0.75)' }}
              />
            </div>
            <div>
              <Text
                className="text-sm text-gray-700 font-light pt-1"
                value={SMS_CREDITS_WITHIN_RANGE_BUT_LESS_THAN_REQUIRED_WARNING}
              />
              <Button kind="link" size="link" onClick={() => handleAddSmsCredits(values)}>
                Add SMS Credits
              </Button>
            </div>
          </div>
        )}
        {creditBalanceState === CREDIT_BALANCE_STATE.BELOW_MINIMUM && (
          <div className="flex flex-row border border-red-300 bg-red-100 p-2">
            <div className="pl-1 my-auto">
              <AlertCircle className="text-red-600 fill-current inline mr-2" style={{ transform: 'scale(0.75)' }} />
            </div>
            <Text className="text-sm text-gray-700 font-light my-auto" value={SMS_CREDITS_BELOW_MINIMUM} />
          </div>
        )}
      </div>
      <div className="px-2 self-center w-full flex flex-row justify-center md:justify-end">
        <div
          className="bg-no-repeat bg-contain h-56 w-68 md:h-80 md:w-104"
          style={{ backgroundImage: `url(${BackgroundIphone})` }}
        >
          <div className="pt-10 md:pt-16">
            {loading ? (
              <div className="mt-16 md:mt-20">
                <Spinner />
              </div>
            ) : error ? (
              <Text value={FAILED_TO_LOAD_CAMPAIGN_SMS_PREVIEW} />
            ) : (
              <div className="flex flex-col">
                <div className="self-center text-white md:text-lg font-bold pt-2">
                  <Text
                    value={
                      resaurantSenderId
                        ? [resaurantSenderId, resaurantSenderId]
                        : [selectedStore.titleEn, selectedStore.titleAr]
                    }
                  />
                </div>
                <div className="mt-6 pl-8 md:mt-16 md:pl-12 overflow-y-scroll w-11/12 h-24 md:h-32" dir="ltr">
                  <p
                    className="w-48 p-3 ml-0 text-xs md:w-64 md:p-4 md:text-base rounded-tr-lg rounded-tl-lg rounded-br-lg bg-blue-100"
                    dir={direction}
                  >
                    {generatedSmsBody}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CampaignReviewStep.propTypes = {
  targetedSegment: PropTypes.oneOf([
    '-',
    SEGMENT_TITLE_MAP.ALL_CUSTOMERS,
    SEGMENT_TITLE_MAP.CHAMPIONS,
    SEGMENT_TITLE_MAP.LOYAL,
    SEGMENT_TITLE_MAP.PROMISING,
    SEGMENT_TITLE_MAP.NEW,
    SEGMENT_TITLE_MAP.LOW_VALUE,
    SEGMENT_TITLE_MAP.NEEDS_ATTENTION,
  ]),
  smsCredits: PropTypes.number.isRequired,
  handleAddSmsCredits: PropTypes.func.isRequired,
  values: PropTypes.shape({
    type: PropTypes.oneOf([CAMPAIGN_TYPES.RETENTION, CAMPAIGN_TYPES.SEGMENT_TARGETING]),
    estimatedCredits: PropTypes.number,
    estimateReach: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    estimateReachWithSendingLimit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

export default CampaignReviewStep;
