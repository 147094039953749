import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { context as localeContext } from 'context/locale';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { Box, Modal, Stack, Spinner } from 'components/kit';
import { Text } from 'components/service';
import { useSelectedStore } from 'hooks/index';
import { INTEGRATIONS_LINKS } from 'constants/helperLinks';
import * as translations from 'constants/translations';
import * as schemas from './schemas';
import FacebookPartnerConnect from './FacebookPartnerConnect';
import POS from './POS';
import Couriers from './Couriers';
import Analytics from './Analytics';

const Root = ({ social = false }) => {
  const storeId = useSelectedStore();
  const { data: settings } = useQuery(schemas.SETTINGS, {
    variables: {
      storeId,
    },
  });
  const { direction } = useContext(localeContext);

  return (
    <Layout
      top={
        <Breadcrumbs
          helperLinks={INTEGRATIONS_LINKS}
          path={social ? translations.breadcrumbs.SOCIAL_MEDIA_TRACKING : translations.breadcrumbs.SETUP_INTEGRATIONS}
        />
      }
    >
      {!settings ? (
        <Spinner />
      ) : (
        <Modal top>
          {({ open, close, closeable }) => (
            <>
              <div className="pb-32 flex" style={{ direction }}>
                <div className="max-w-3xl w-full">
                  <Stack direction="col" spacing={2} expandItem>
                    {social && (
                      <div>
                        <Box
                          title={<Text className="font-semibold text-lg mb-2" value={translations.TOOLS} />}
                          body={
                            <div className="pb-2 px-4">
                              <FacebookPartnerConnect />
                            </div>
                          }
                        />
                        <div className="mt-4">
                          <Analytics />
                        </div>
                      </div>
                    )}

                    {!social && (
                      <>
                        <div className="mb-6">
                          <POS {...{ open, close, closeable }} />
                        </div>
                        <Couriers {...{ open, close, closeable }} />
                      </>
                    )}
                  </Stack>
                </div>
              </div>
            </>
          )}
        </Modal>
      )}
    </Layout>
  );
};

Root.propTypes = {
  social: PropTypes.bool,
};

export default Root;
