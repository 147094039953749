import React, { useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { Button, Modal } from 'components/kit';
import { context as localeContext } from 'context/locale';
import { ReactComponent as SettingsIcon } from 'assets/settings.svg';
import TrackingTypeChange from './TrackingTypeChange';

const ItemEditPopUp = ({ menuItemDataTrack, productId, settings = false }) => {
  const { lang } = useContext(localeContext);

  return (
    <div>
      <Modal>
        {({ open }) => (
          <>
            <Button
              kind="transparent"
              onClick={() =>
                open({
                  size: 'max-w-sm',
                  title: (
                    <div className={cx('flex justify-between items-center', lang === 'ar' && 'flex-row-reverse')}>
                      <div className="flex flex-col">
                        <Text className="text-xl leading-5 font-semibold" value={translations.ITEM_TRACKING_SETTINGS} />
                      </div>
                    </div>
                  ),
                  body: (
                    <div className="mt-2 mb-0 mx-auto px-4">
                      <div className="flex flex-col items-center">
                        <div className="my-0 mx-0">
                          <Text
                            value={translations.ITEM_TRACKING}
                            className={cx('font-medium text-base', lang === 'ar' ? 'text-right' : 'text-left')}
                          />
                          <div>
                            <TrackingTypeChange menuItemDataTrack={menuItemDataTrack} productId={productId} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
                })
              }
            >
              {settings ? <SettingsIcon /> : <Text value={translations.ENABLE_TRACKING} />}
            </Button>
          </>
        )}
      </Modal>
    </div>
  );
};

ItemEditPopUp.propTypes = {
  settings: PropTypes.bool,
  productId: PropTypes.string.isRequired,
  menuItemDataTrack: PropTypes.string.isRequired,
};

export default ItemEditPopUp;
