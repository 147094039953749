import { gql } from '@apollo/client';

export const UPDATE_RESTAURANT = gql`
  mutation updateRestaurant(
    $id: String!
    $fbadTrackId: String
    $gaTrackId: String
    $snapTrackId: String
    $gtmTrackId: String
  ) {
    updateRestaurant(
      id: $id
      fbadTrackId: $fbadTrackId
      gaTrackId: $gaTrackId
      snapTrackId: $snapTrackId
      gtmTrackId: $gtmTrackId
    ) {
      id
      fbadTrackId
      gaTrackId
      snapTrackId
      gtmTrackId
    }
  }
`;
