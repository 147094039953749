import React, { useContext } from 'react';
import { navigate } from '@reach/router';
import { Text } from 'components/service';
import { useMutation } from '@apollo/client';
import cx from 'classnames';
import { useReplaceParams } from 'hooks';
import { context as notificationsContext } from 'context/notifications';
import { context as localeContext } from 'context/locale';
import { More, Modal } from 'components/kit';
import * as translations from 'constants/translations';
import * as schemas from '../schemas';
import * as paths from 'paths.js';
import DetailsCampaign from './DetailsCampaign';
import DeleteCampaign from './DeleteCampaign';

const CampaignsTableRow = ({
  item,
  index,
  totalCount,
  campaignsVariables,
  storeId,
  isSlideOverOpen,
  setIsSlideOverOpen,
  setSlideOverComponent,
}) => {
  const { direction, translate } = useContext(localeContext);
  const cellClassName = cx(
    'px-4 border-b border-gray-300 whitespace-nowrap last:w-full',
    totalCount <= 2 ? (totalCount - 1 > index ? 'py-5' : 'pt-5 pb-40') : 'py-5',
  );

  const status = {
    activated: (
      <div className="flex items-center">
        <span className="w-2 h-2 rounded-full bg-zyda-yellow-500 mx-2" />
        {translate(translations.ACTIVE)}
      </div>
    ),
    scheduled: (
      <div className="flex items-center">
        <span className="w-2 h-2 rounded-full bg-zyda-red-300 mx-2" />
        {translate(translations.SCHEDULED)}
      </div>
    ),
    deactivated: (
      <div className="flex items-center">
        <span className="w-2 h-2 rounded-full bg-zyda-grey-500 mx-2" />
        {translate(translations.DEACTIVATED)}
      </div>
    ),
    completed: (
      <div className="flex items-center">
        <span className="w-2 h-2 rounded-full bg-zyda-green-500 mx-2" />
        {translate(translations.COMPLETED_CAMPAIGN)}
      </div>
    ),
    draft: (
      <div className="flex items-center">
        <span className="w-2 h-2 rounded-full bg-primary-base mx-2" />
        {translate(translations.DRAFT_CAMPAIGN)}
      </div>
    ),
  };

  const type = {
    RetentionCampaign: translate(translations.RETENTION),
    RetargetingCampaign: translate(translations.SEGMENT_TARGETING),
  };

  return (
    <tr>
      <td className={cx(cellClassName)}>{type[item.type]}</td>
      <td className={cx(cellClassName)}>{item.startsAt}</td>
      <td className={cx(cellClassName)}>{item.expiresAt}</td>
      <td className={cx(cellClassName)}>
        {`${item.sentSmsCount} / `}
        <span className="text-gray-700">{item.smsSendingLimit || translate(translations.UNLIMITED)}</span>
      </td>
      <td className={cx(cellClassName)}>
        <Status
          index={index}
          status={status[item.state]}
          totalCount={totalCount}
          direction={direction}
          campaignId={item.id}
          campaignsVariables={campaignsVariables}
          storeId={storeId}
          item={item}
          isSlideOverOpen={isSlideOverOpen}
          setIsSlideOverOpen={setIsSlideOverOpen}
          setSlideOverComponent={setSlideOverComponent}
        />
      </td>
    </tr>
  );
};

export default CampaignsTableRow;

const Status = ({
  status,
  direction,
  campaignId,
  item,
  campaignsVariables,
  storeId,
  index,
  totalCount,
  setIsSlideOverOpen,
  setSlideOverComponent,
}) => {
  const notifications = useContext(notificationsContext);
  const replace = useReplaceParams();

  const [updateCampaign, { loading }] = useMutation(schemas.UPDATE_CAMPAIGN, {
    variables: {
      storeId,
      campaignId,
    },
    onError: err => {
      let body = err.graphQLErrors[0].extensions.exception.body;

      for (let key of Object.keys(body)) {
        notifications.show(`${body[key][0]}`, 'error');
      }
    },
    onCompleted: data => {
      notifications.show(<Text value={translations.CAMPAIGN_UPDATED} />);
    },
  });

  return (
    <div className="flex items-center justify-between -my-2">
      <div className="">{status}</div>
      <div className={cx(direction === 'ltr' ? 'ml-2' : 'mr-2')}>
        <Modal>
          {({ open, close }) => (
            <More
              modalUp={totalCount > 2 && (totalCount - 1 === index || totalCount - 2 === index)}
              kind="action"
              size="smIcon"
              items={[
                {
                  title: <Text value={translations.VIEW_DETAILS} />,
                  onClick: () => {
                    setSlideOverComponent(
                      <DetailsCampaign item={item} storeId={storeId} setIsSlideOverOpen={setIsSlideOverOpen} />,
                    );
                    setIsSlideOverOpen(true);
                  },
                },
                {
                  title: <Text value={translations.EDIT} />,
                  onClick: () => {
                    navigate(
                      replace(paths.editCampaign, {
                        params: { campaignId },
                      }),
                    );
                  },
                },
                {
                  title:
                    item.state === 'activated' ? (
                      <Text value={translations.DEACTIVATE} />
                    ) : (
                      <Text value={translations.ACTIVATE} />
                    ),
                  onClick: () => {
                    updateCampaign({
                      variables: {
                        state: item.state === 'activated' ? 'deactivated' : 'activated',
                      },
                    });
                  },
                  disabled: item.state === 'completed',
                },
                {
                  title: <Text value={translations.DELETE} />,
                  onClick: () => {
                    open({
                      title: item.title,
                      body: (
                        <DeleteCampaign
                          campaignId={campaignId}
                          onClose={close}
                          campaignsVariables={campaignsVariables}
                        />
                      ),
                      size: 'max-w-md',
                    });
                  },
                },
              ]}
            />
          )}
        </Modal>
      </div>
    </div>
  );
};
