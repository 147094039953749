import React, { useContext, useState, useEffect } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { isObject } from 'formik';
import cx from 'classnames';

import { Box, Button, Spinner, ModalConfirmBoxBody, ModalConfirmBoxHeader } from 'components/kit';
import { Text } from 'components/service';
import { context as localeContext } from 'context/locale';
import { context as notificationsContext } from 'context/notifications';
import { context as userContext } from 'context/user';
import { useSelectedStore } from 'hooks';
import * as translations from 'constants/translations';
import CheckBox from 'components/kit/CheckBox';
import { THIRD_PARTY_TYPE_ENUM } from 'utils/enums';
import { EXTERNAL_RESPONSE } from 'constants/constant-errors';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import * as CONSTANTS from 'constants/integrations';
import convertMultiLanguagesFromObjectToArray from '../../utils';
import * as schemas from './schemas';
import CardHeader from './Header';
import ModalBody from './Modal';

const IntegrationCard = ({ onOpen, onClose, closeable, courier, courierType }) => {
  const storeId = useSelectedStore();
  const { data: settings } = useQuery(schemas.SETTINGS, {
    variables: { storeId },
  });

  const { data: connectedDeliverectBranches, refetch: refetchConnectedBranches } = useQuery(
    schemas.CONNECTED_DELIVERECT_BRANCHES,
    {
      variables: { storeId },
      fetchPolicy: 'cache-and-network',
    },
  );

  const {
    integrationData: {
      courierBasicInfo: { title, description },
    },
    isBeta,
    logoUrl,
  } = courier;
  const [loadingCouriers, setLoadingCouriers] = useState(false);

  const { direction, translate, lang } = useContext(localeContext);
  const {
    selectedStore: { restaurantCourierSetting },
    setSelectedStore,
    isPosCourier,
  } = useContext(userContext);
  const notifications = useContext(notificationsContext);
  const allCourierIntegrations = restaurantCourierSetting.filter(
    _courier => _courier.businessType.toLowerCase() === courierType.toLowerCase(),
  );
  const currentCourierIntegration = allCourierIntegrations.find(_courier => _courier.courierDetails?.id === courier.id);
  const [connected, setConnected] = useState(!!currentCourierIntegration);
  const [supportFeeFromCourier, setFeeFromCourier] = useState();
  const [supportTimeFromCourier, setTimeFromCourier] = useState();
  const [status, setStatus] = useState(!connected ? translations.DISCONNECTED : translations.CONNECTED);
  const supportCourierTimeAndFee = currentCourierIntegration?.supportMsValidationRequest;
  const isDefaultCourier = currentCourierIntegration?.default;
  const showFeeTimeCheckBoxes =
    connected && courierType === THIRD_PARTY_TYPE_ENUM.Delivery.toLowerCase() && supportCourierTimeAndFee;
  const connectedWithDeliverect = connected && courier?.name === CONSTANTS.DELIVERECT;
  const notDefaultCourier =
    connected && !isDefaultCourier && courierType === THIRD_PARTY_TYPE_ENUM.Delivery.toLowerCase();
  const defaultCourier = connected && isDefaultCourier && courierType === THIRD_PARTY_TYPE_ENUM.Delivery.toLowerCase();
  const isConnectedCourier = currentCourierIntegration?.courierDetails?.id === courier.id;
  useEffect(() => {
    let newStatus = !connected ? CONSTANTS.DISCONNECTED : CONSTANTS.CONNECTED;
    if (connectedDeliverectBranches && courier?.name === CONSTANTS.DELIVERECT) {
      const { connectedPosBranches } = connectedDeliverectBranches;
      const partiallyConnected = connectedPosBranches.some(el => el.branchExternalId === null);
      newStatus = partiallyConnected ? CONSTANTS.PARTIALLY_CONNECTED : newStatus;
    }
    setConnected(isConnectedCourier);
    setStatus(CONSTANTS[newStatus]);
  }, [courier, restaurantCourierSetting, currentCourierIntegration, connectedDeliverectBranches]);

  useEffect(() => {
    if (currentCourierIntegration) {
      setFeeFromCourier(currentCourierIntegration.isFeeFromCourier);
      setTimeFromCourier(currentCourierIntegration.isTimeFromCourier);
    }
  }, [currentCourierIntegration]);
  const [getRestaurantCourierSettings] = useLazyQuery(schemas.RESTAURANT, {
    variables: {
      storeId,
    },
    onCompleted: ({ restaurant }) => {
      refetchConnectedBranches();
      setSelectedStore(restaurant);
      setLoadingCouriers(false);
    },
    onError: err => {
      throw err;
    },
    fetchPolicy: 'cache-and-network',
  });

  const changeSettings = (newStatus = true) => {
    setLoadingCouriers(true);
    setConnected(newStatus);
    getRestaurantCourierSettings();
  };

  const [disconnectCourier] = useMutation(schemas.DELETE_COURIER_SETTINGS, {
    onCompleted: () => {
      notifications.show(translations.DISCONNECTED_SUCCESSFULLY);
      changeSettings(false);
    },
    onError: error => {
      if (!error.graphQLErrors) throw error;
      Object.keys(error.graphQLErrors[0].extensions.exception.body).forEach(key => {
        if (key === EXTERNAL_RESPONSE) {
          if (isObject(error.graphQLErrors[0].extensions.exception.body[key])) {
            notifications.show(error.graphQLErrors[0].extensions.exception.body[key]?.message, 'error');
          } else {
            notifications.show(
              JSON.parse(error.graphQLErrors[0].extensions.exception.body[key]).restaurant_courier_settings,
              'error',
            );
          }
        }
      });
    },
  });

  const [updateCourierSetting] = useMutation(schemas.UPDATE_COURIER_SETTINGS, {
    onCompleted: () => {
      changeSettings(true);
    },
    onError: error => {
      if (!error.graphQLErrors) throw error;
      Object.keys(error.graphQLErrors[0].extensions.exception.body).forEach(key => {
        if (key === EXTERNAL_RESPONSE) {
          if (isObject(error.graphQLErrors[0].extensions.exception.body[key])) {
            notifications.show(error.graphQLErrors[0].extensions.exception.body[key]?.message, 'error');
          } else {
            notifications.show(
              JSON.parse(error.graphQLErrors[0].extensions.exception.body[key]).restaurant_courier_settings,
              'error',
            );
          }
        }
      });
    },
  });
  return (
    <Box
      border
      title={<CardHeader logo={logoUrl} isBeta={isBeta} status={status} title={title} />}
      description={
        <div className="flex flex-col">
          <Text value={convertMultiLanguagesFromObjectToArray(description)} className="mt-4 text-gray-400" />
          {showFeeTimeCheckBoxes && (
            <div className="flex mt-2 w-3/4 justify-between">
              <div className="flex">
                <CheckBox
                  value={supportFeeFromCourier}
                  lang={lang}
                  onChange={value => {
                    setFeeFromCourier(value);
                    updateCourierSetting({
                      variables: {
                        restaurantId: storeId,
                        courierId: courier.id.toString(),
                        isFeeFromCourier: value,
                      },
                    });
                  }}
                />
                <Text
                  value={translations.IS_FEE_FROM_COURIER}
                  payload={lang === 'en' ? `${title.en}` : `${title.ar}`}
                />
              </div>
              <div className="flex">
                <CheckBox
                  value={supportTimeFromCourier}
                  lang={lang}
                  onChange={value => {
                    setTimeFromCourier(value);
                    updateCourierSetting({
                      variables: {
                        restaurantId: storeId,
                        courierId: courier.id.toString(),
                        isTimeFromCourier: value,
                      },
                    });
                  }}
                />
                <Text
                  value={translations.IS_TIME_FROM_COURIER}
                  payload={lang === 'en' ? `${title.en}` : `${title.ar}`}
                />
              </div>
            </div>
          )}
        </div>
      }
      action={
        (!connected && courierType === THIRD_PARTY_TYPE_ENUM.Delivery.toLowerCase()) ||
        (!isPosCourier && courierType === THIRD_PARTY_TYPE_ENUM.POS.toLowerCase()) ? (
          <Button
            size="base"
            kind="transparent"
            className="bg-blue-100"
            weight="normal"
            isRounded
            data-testid={`get-started-${courier.name}`}
            onClick={() =>
              onOpen({
                title: (
                  <div style={{ direction }} className="flex items-center justify-between">
                    <CardHeader logo={logoUrl} isBeta={isBeta} title={title} />
                    <CloseIcon
                      className={cx('cursor-pointer absolute', lang === 'en' ? 'right-3' : 'left-3')}
                      onClick={() => {
                        changeSettings();
                        closeable(true);
                        onClose();
                      }}
                    />
                  </div>
                ),
                body: (
                  <ModalBody
                    courier={courier}
                    settings={settings}
                    changeSettings={changeSettings}
                    onCancel={onClose}
                    closeable={closeable}
                    courierType={courierType}
                  />
                ),
                size: 'max-w-2xl',
              })
            }
          >
            {loadingCouriers ? <Spinner /> : <Text value={translations.GET_STARTED} />}
          </Button>
        ) : (
          <div className="flex">
            {connectedWithDeliverect && (
              <Button
                kind="outline"
                weight="normal"
                size="base"
                data-testid={`link-branches-${courier.name}`}
                className="mx-2"
                isRounded
                onClick={() =>
                  onOpen({
                    title: (
                      <div style={{ direction }} className="flex items-center justify-between">
                        <CardHeader logo={logoUrl} isBeta={isBeta} title={title} />
                        <CloseIcon
                          className={cx('cursor-pointer absolute', lang === 'en' ? 'right-3' : 'left-3')}
                          onClick={() => {
                            changeSettings();
                            closeable(true);
                            onClose();
                          }}
                        />
                      </div>
                    ),
                    body: (
                      <ModalBody
                        courier={courier}
                        settings={settings}
                        storeId={storeId}
                        changeSettings={changeSettings}
                        onCancel={onClose}
                        closeable={closeable}
                        courierType={courierType}
                        defaultStep={2}
                      />
                    ),
                    size: 'max-w-2xl',
                  })
                }
              >
                <Text value={translations.DELIVERECT_MODAL_LINK_BRANCHES} />
              </Button>
            )}
            {notDefaultCourier && (
              <Button
                kind="outline"
                weight="normal"
                size="base"
                className="mx-2"
                data-testid={`${courier.name}-makeDefault`}
                isRounded
                onClick={() =>
                  onOpen({
                    title: <ModalConfirmBoxHeader headerText={translations.ARE_YOU_SURE_THIS} />,
                    body: (
                      <ModalConfirmBoxBody
                        onClick={() => {
                          updateCourierSetting({
                            variables: {
                              restaurantId: storeId,
                              courierId: courier.id.toString(),
                              isDefault: true,
                            },
                          });
                          onClose();
                        }}
                        onCancel={onClose}
                        areYouSureText={translations.SET_DEFAULT_COURIER}
                        testid={`makeDefault-${courier.name}`}
                      />
                    ),
                    size: 'max-w-xs',
                  })
                }
              >
                <Text value={translations.MAKE_DEFAULT} />
              </Button>
            )}
            {defaultCourier && (
              <div className="mx-2 text-zyda-black-100 text-base font-medium flex items-center">
                <Text value={translations.DEFAULT_COURIER} />
              </div>
            )}
            {connected && (
              <Button
                size="base"
                data-testid={`${courier.name}-disconnect`}
                kind="transparent"
                className="bg-blue-100"
                weight="normal"
                isRounded
                onClick={() =>
                  onOpen({
                    title: <ModalConfirmBoxHeader headerText={translations.DISCONNECT_COURIER} />,
                    body: (
                      <ModalConfirmBoxBody
                        onClick={() => {
                          disconnectCourier({
                            variables: {
                              restaurantId: storeId,
                              courierId: courier.id.toString(),
                            },
                          });
                          onClose();
                        }}
                        onCancel={onClose}
                        areYouSureText={translations.ARE_YOU_SURE_TO_DISCONNECT(
                          translate(convertMultiLanguagesFromObjectToArray(title)),
                        )}
                        testid={`disconnect-${courier.name}`}
                      />
                    ),
                    size: 'max-w-xs',
                  })
                }
              >
                <Text value={translations.DISCONNECT} />
              </Button>
            )}
          </div>
        )
      }
    />
  );
};

IntegrationCard.propTypes = {
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  closeable: PropTypes.func.isRequired,
  courier: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    isBeta: PropTypes.bool.isRequired,
    logoUrl: PropTypes.string.isRequired,
    integrationData: PropTypes.shape({
      courierBasicInfo: PropTypes.shape({
        title: PropTypes.shape({
          en: PropTypes.string.isRequired,
          ar: PropTypes.string.isRequired,
        }).isRequired,
        description: PropTypes.shape({
          en: PropTypes.string.isRequired,
          ar: PropTypes.string.isRequired,
        }).isRequired,
      }),
    }),
  }),
  courierType: PropTypes.string.isRequired,
};

export default IntegrationCard;
