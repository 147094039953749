import React, { useContext } from 'react';
import moment from 'moment';
import cx from 'classnames';
import ReactTooltip from 'react-tooltip';

import { useQueryState } from 'hooks';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { context as notificationsContext } from 'context/notifications';
import DATE_RANGES from 'constants/date';
import { breadcrumbs, ANALYTICS_TOOLTIP_INFORMATION } from 'constants/translations';
import { Layout, Breadcrumbs } from 'components/common/dashboard';
import { DateRange } from 'components/common/analytics';
import { Text } from 'components/service';
import { parseRange, stringifyRange, getRange, toRangeVariable } from 'utils/date';
import { ReactComponent as AlertCircle } from 'assets/Icons_Warning.svg';
import Products from '../Products';
import { ANALYTICS_TAB_NAMES } from '../../constants';
import momentLanguage from '../../utils';
import { BEST_SELLING_LINKS } from 'constants/helperLinks';

const BestSelling = () => {
  const notifications = useContext(notificationsContext);
  const { selectedStore } = useContext(userContext);
  const { direction, lang } = useContext(localeContext);
  const [query, setQuery] = useQueryState({
    type_date: DATE_RANGES.THIRTY_DAYS,
    date: 'day',
    range: stringifyRange(getRange(DATE_RANGES.THIRTY_DAYS)),
    main_selection: ANALYTICS_TAB_NAMES.totalSales.snakeCaseName,
    behavior_selection: ANALYTICS_TAB_NAMES.totalSales.snakeCaseName,
    behavior_date: 'day',
  });

  const range = parseRange(query.range);
  const rangeVariable = toRangeVariable(range);

  const length = breadcrumbs.BEST_SELLING[0].length - 1;

  moment.locale(momentLanguage(lang));

  return (
    <Layout
      top={
        <Breadcrumbs
          helperLinks={BEST_SELLING_LINKS}
          path={breadcrumbs.BEST_SELLING}
          customTitle={
            <div className={cx('flex', lang === 'en' ? 'flex-row' : 'flex-row-reverse')}>
              <Text
                className="text-2xl font-bold text-black"
                value={[breadcrumbs.BEST_SELLING[0][length], breadcrumbs.BEST_SELLING[1][length]]}
              />
              <div>
                <AlertCircle
                  data-tip
                  data-for="bestSellingTooltip"
                  className="text-gray-600 fill-current inline mr-2 ml-1 mt-1"
                  style={{ transform: 'scale(0.75)' }}
                />
                <ReactTooltip place="bottom" type="dark" effect="solid" id="bestSellingTooltip">
                  <ul className="list-disc" dir={lang === 'en' ? 'ltr' : 'rtl'}>
                    {ANALYTICS_TOOLTIP_INFORMATION.map(singleInformationLine => (
                      <li>
                        <Text value={singleInformationLine} />
                      </li>
                    ))}
                  </ul>
                </ReactTooltip>
              </div>
            </div>
          }
          right={
            <DateRange
              queryTypeDate={query.type_date}
              range={range}
              selectedStoreCreatedAt={selectedStore.createdAt}
              notifications={notifications}
              setQuery={setQuery}
              direction={direction}
            />
          }
        />
      }
    >
      <div className="pb-20" style={{ direction }}>
        <div className="w-full mb-12">
          <Products range={rangeVariable} />
        </div>
      </div>
    </Layout>
  );
};

export default BestSelling;
