import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Text } from 'components/service';
import { Button } from 'components/kit';

function FacebookActionCard({ description, fbLink, buttonText, installed }) {
  return (
    <div className="flex flex-col mb-3 sm:mb-0 sm:mr-2 w-4/5 p-4 border justify-between">
      <Text value={description} className="text-xs font-medium text-gray-700 mb-1" />
      <div className="mt-4">
        <Button disabled={!installed} kind="transparent" size="close" onClick={() => (window.open(fbLink, '_blank').focus())}>
          <Text className="text-xs font-medium" value={buttonText} />
        </Button>
      </div>
    </div>
  );
}

FacebookActionCard.propTypes = {
  description: PropTypes.array.isRequired,
  fbLink: PropTypes.string.isRequired,
  buttonText: PropTypes.array.isRequired,
  installed: PropTypes.bool.isRequired
};

export default FacebookActionCard;
