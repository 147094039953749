import React, { useState, useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import { context as userContext } from 'context/user';
import { context as localeContext } from 'context/locale';
import { useSelectedStore } from 'hooks/index';
import { UNINSTALL_FB } from './schema';
import { Box, Button } from 'components/kit';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import FacebookIcon from 'assets/facebook.png';
import { context as notificationsContext } from 'context/notifications';
import FacebookActionCard from './FacebookActionCard';
import { fbRedirectUrl } from 'constants/fb';

const FacebookPartnerConnect = () => {
  const { selectedStore } = useContext(userContext);
  const { direction } = useContext(localeContext);
  const notifications = useContext(notificationsContext);
  const storeId = useSelectedStore();
  const [installed, setInstalled] = useState(selectedStore.isFbeConnected);

  const [uninstallFB, { loading: isStillUpdating }] = useMutation(UNINSTALL_FB, {
    onCompleted: () => {
      notifications.show(<Text value={translations.FACEBOOB_UNINSTALLED} />), setInstalled(false);
    },
    onError: () => notifications.show(<Text value={translations.SOMETHING_WENT_WRONG} />, 'error'),
  });

  const { id: restaurantId, titleEn, titleAr, fbeBusinessManagerId } = selectedStore;
  return (
    <div>
      <Box
        border
        title={
          <div className="flex items-center">
            <img src={FacebookIcon} alt="Facebook Pixel" className={cx('w-6', direction === 'rtl' ? 'ml-3' : 'mr-3')} />
            <Text className="font-semibold text-lg" value={translations.FACEBOOK_PARTNER_TITLE} />
          </div>
        }
        description={
          <>
            <div className="flex flex-col mb-3 sm:mb-0 sm:mr-2 w-4/5">
              <Text value={translations.FACEBOOK_PARTNER_DESCRIPTION} className="text-xs text-gray-700 mb-1" />
            </div>
            <div className="mt-4" />
            {installed && <hr className="bg-gray-200 my-auto mx-0 border" />}
            {installed && (
              <div className="flex justify-between mt-4">
                <div className="flex justify-between">
                  <div>
                    <Text className="text-gray-500 text-xs" value={translations.BUSINESS_NAME} />
                    <p className="text-lg leading-compact">{direction === 'ltr' ? titleEn : titleAr}</p>
                  </div>
                  <div className={cx(direction === 'ltr' ? 'ml-8' : 'mr-8')}>
                    <Text className="text-gray-500 text-xs" value={translations.BUSINESS_ID} />
                    <p className="text-lg leading-compact">zyda-{restaurantId}</p>
                  </div>
                  <div className={cx(direction === 'ltr' ? 'ml-10' : 'mr-10')}>
                    <Text className="text-gray-500 text-xs" value={translations.STATUS_ORDER} />
                    <Text className="text-lg leading-compact text-green-600" value={translations.CONNECTED} />
                  </div>
                </div>
                <div>
                  <a
                    class="text-primary-base"
                    href={`https://www.facebook.com/facebook_business_extension?app_id=846583795969804&external_business_id=${restaurantId}`}
                  >
                    <button>
                      <Text className="text-sm" value={translations.MANAGE_FEATURES} />
                    </button>
                  </a>
                </div>
              </div>
            )}
          </>
        }
        action={
          installed ? (
            <Button
              onClick={() =>
                uninstallFB({
                  variables: {
                    restaurantId: storeId,
                  },
                })
              }
              size="base"
              kind="secondaryError"
              isSpinning={isStillUpdating}
              disabled={isStillUpdating}
            >
              <Text value={translations.DISCONNECT} />
            </Button>
          ) : (
            <Button
              kind="tertiary"
              size="base"
              onClick={() => {
                sessionStorage.setItem('facebook_business_id', restaurantId);
                window.location = `https://www.facebook.com/dialog/oauth?client_id=846583795969804&display=page&redirect_uri=https://dash.zyda.com/&state=${restaurantId}&response_type=token&scope=manage_business_extension${fbRedirectUrl(
                  selectedStore,
                )}`;
              }}
            >
              <Text value={translations.CONNECT} />
            </Button>
          )
        }
      />
      <div className="mt-4">
        <Box
          border
          title={
            <div className="flex items-center mb-4">
              <Text className="font-semibold text-lg" value={translations.FB_ACTIONS} />
            </div>
          }
          description={
            <div className="flex justify-between">
              <FacebookActionCard
                fbLink={`https://business.facebook.com/latest/posts/?business_id=${fbeBusinessManagerId}`}
                description={translations.PROMOTE_FB}
                buttonText={translations.PROMOTE_SERVICES}
                installed={installed}
              />
              <FacebookActionCard
                fbLink={`https://business.facebook.com/latest/inbox/all?business_id=${fbeBusinessManagerId}`}
                description={translations.REACH_FB}
                buttonText={translations.MANAGE_CUSTOMERS}
                installed={installed}
              />
              <FacebookActionCard
                fbLink={`https://business.facebook.com/latest/ad_center/?business_id=${fbeBusinessManagerId}`}
                description={translations.CAMPAIGNS_MANAGE}
                buttonText={translations.RUN_ADS}
                installed={installed}
              />
            </div>
          }
        />
      </div>
    </div>
  );
};

export default FacebookPartnerConnect;
