import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { context as localeContext } from 'context/locale';
import { context as customersContext } from 'context/customers';
import Segment from 'pages/customers/Customers/Segment';
import { SEGMENT_TITLE_MAP } from 'constants/customers';

const SegmentsFilter = ({ currentSegment = null, handleOnClick, hasAllCustomers = false }) => {
  const { lang } = useContext(localeContext);
  const { segments } = useContext(customersContext);
  const filteredSegmentsKeys = hasAllCustomers
    ? Object.keys(segments)
    : Object.keys(segments).filter(key => parseInt(key) !== SEGMENT_TITLE_MAP.ALL_CUSTOMERS);

  const handleFilterChange = filter => {
    let setFilter;
    if (parseInt(currentSegment) === filter) setFilter = null;
    else setFilter = filter;
    handleOnClick(setFilter);
  };

  return (
    <div
      className={cx('grid grid-col-1 gap-4 mb-6', hasAllCustomers ? 'lg:grid-cols-4' : 'md:grid-cols-2 lg:grid-cols-3')}
    >
      {filteredSegmentsKeys.map(key => (
        <Segment
          key={segments[key].segmentId}
          segmentId={segments[key].segmentId}
          handleFilterChange={handleFilterChange}
          currentFilter={currentSegment && parseInt(currentSegment)}
          segmentCss={cx(segments[key].segmentBaseCss, segments[key].segmentFilterCss)}
          segmentIcon={segments[key].segmentIcon}
          segmentName={lang === 'en' ? segments[key].titleEn : segments[key].titleAr}
          segmentText={lang === 'en' ? segments[key].descriptionEn : segments[key].descriptionAr}
          numberOfUsersBelongingToSegment={segments[key].customersCount}
          percentageOfUsersInSegment={segments[key].customerPercentage}
          lang={lang}
          hasAllCustomers={segments[key].segmentId === SEGMENT_TITLE_MAP.ALL_CUSTOMERS}
        />
      ))}
    </div>
  );
};

SegmentsFilter.propTypes = {
  currentSegment: PropTypes.string,
  handleOnClick: PropTypes.func.isRequired,
  hasAllCustomers: PropTypes.bool,
};

export default SegmentsFilter;
