import React, { useContext } from 'react';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { Button, Modal } from 'components/kit';
import { ReactComponent as BusinessLocation } from 'assets/ElementCard45.svg';
import { context as localeContext } from 'context/locale';

export default () => {
  const { translate } = useContext(localeContext);

  return (
    <Modal>
      {({ open, close }) => (
        <>
          <Button
            kind="primary"
            onClick={() =>
              open({
                size: 'max-w-md',
                body: (
                  <div className="mt-10 mb-0 mx-auto w-5/6">
                    <div className="flex flex-col items-center">
                      <BusinessLocation width="60%" />
                      <div className="my-8 mx-0">
                        <p className="text-2xl font-bold text-black mb-2 mt-0 mx-0">
                          {translate(translations.GLAD_YOU_EXPAND)}
                        </p>
                        <p className="my-0 mx-0 text-black text-lg">
                          {translate(translations.SUPPORT_TEAM_IS_READY_TO_HELP)}
                        </p>
                      </div>
                      <Button full kind="primary" onClick={() => window.Intercom('show')}>
                        <p className="text-sm font-semibold text-white">{translate(translations.REQUEST_NEW_BRANCH)}</p>
                      </Button>
                      <Button onClick={() => close()} kind="borderlessButton">
                        <p className="text-gray-700 text-base">{translate(translations.CREATE_LATER)}</p>
                      </Button>
                    </div>
                  </div>
                ),
              })
            }
          >
            <Text value={translations.ADD_BUSINESS_LOCATION} />
          </Button>
        </>
      )}
    </Modal>
  );
};
